import { Tooltip as AntTooltip } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IHeadingProps {
  /**
   * Button label
   */
  children?: React.ReactNode;
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Color
   */
  color?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * id assigned to mark this heading
   */
  id?: string;
  /**
   * Heading level
   */
  level: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  /**
   * Defines space around outside of heading using CSS margin syntax
   */
  margin?: string;
  /**
   * Maximum width of the heading
   */
  maxWidth?: string;
  /**
   * Do not include tooltip
   */
  noTooltip?: boolean;
  /**
   * Alignment of header text
   */
  align?: "center" | "left" | "right";
  /**
   * Font weight
   */
  weight?: "regular" | "medium" | "bold";
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const StyledHeading = styled<React.FunctionComponent<IHeadingProps>>(React.forwardRef((props, ref) => {
  const newProps: any = { ...props };
  delete newProps.margin;
  delete newProps.color;
  delete newProps.variation;
  delete newProps.maxWidth;
  delete newProps.level;
  delete newProps.weight;
  delete newProps.align;
  delete newProps.noTooltip;
  const component = props.level >= 7 ? "div" : `h${props.level}`;
  const Component = component as any;
  return <Component ref={ref} {...newProps}>{props.children}</Component>;
}))`
&& {
  ${props => {
    const fontWeights = { regular: "400", medium: "500", bold: "700" };
    return props.weight
      ? "font-weight: " + fontWeights[props.weight] + ";"
      : "";
  }}
  ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
  ${props => (props.align ? "text-align: " + props.align + ";" : "")}
  ${props =>
    props.maxWidth
      ? "max-width: " +
        props.maxWidth +
        "; " +
        "overflow: hidden; " +
        "text-overflow: ellipsis; " +
        "white-space: nowrap;"
      : ""}
`;

/**
 * A heading.
 */
export class Heading extends React.PureComponent<IHeadingProps> {
  public render() {
    const newProps: IHeadingProps = { ...this.props };
    newProps.className +=
      this.props.level >= 7
        ? ` ant-heading-${this.props.level} `
        : " ant-heading ";
    newProps.className += this.props.color
      ? "ant-fg-" +
        this.props.color +
        (this.props.variation ? "-" + this.props.variation : "")
      : "";
    if (this.props.maxWidth && !this.props.noTooltip) {
      return (
        <AntTooltip title={this.props.children}>
          <StyledHeading {...newProps}>{this.props.children}</StyledHeading>
        </AntTooltip>
      );
    } else {
      return <StyledHeading {...newProps}>{this.props.children}</StyledHeading>;
    }
  }
}
