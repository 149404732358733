class SVGPointUtils {
  public static getOutRectLeftCoords(x: number, y: number, indent: number) {
    return `${x},${y - indent} ${x},${y + indent} ${x - indent},${y}`;
  }

  public static getOutRectRightCoords(x: number, y: number, indent: number) {
    return `${x},${y - indent} ${x},${y + indent} ${x + indent},${y}`;
  }

  public static getTriangleLeftCoords(x: number, y: number, indent: number) {
    return `${x - 0.5},${y - indent} ${x - 0.5},${y + indent} ${x -
      indent -
      0.5},${y}`;
  }

  public static getTriangleRightCoords(x: number, y: number, indent: number) {
    return `${x + 0.5},${y - indent} ${x + 0.5},${y + indent} ${x +
      indent +
      0.5},${y}`;
  }
}

export default SVGPointUtils;
