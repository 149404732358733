import * as React from "react";
import PointUtils from "./PointUtils";
import styled from "styled-components";
import { ScreenChartStyle } from "./Styles/ScreenChartStyle";

interface IPointShapeProps {
  type: string;
}

export class PointShape extends React.PureComponent<IPointShapeProps> {
  public render() {
    const StyledSvg = styled.svg`
      && {
        ${ScreenChartStyle}
      }
      && {
        height: 20px;
        width: 20px;
        padding-left: 0px;
        fill: #69c0ff;
      }
    `;
    const blueFill = "#69c0ff";
    if (
      this.props.type === "pretest" ||
      this.props.type === "posttest" ||
      this.props.type === "probe"
    ) {
      const className = `ct-selected ct-dot ct-${this.props.type}`;
      return (
        <StyledSvg type={this.props.type} fill="#69c0ff" className={className} viewBox="0 0 18 18">
          <polygon
            points={PointUtils.getOutRectLeftCoords(9, 9, 11)}
            className="ct-out-rect"
          />
          <polygon
            points={PointUtils.getOutRectRightCoords(9, 9, 11)}
            className="ct-out-rect"
          />
          <polygon
            points={PointUtils.getTriangleLeftCoords(9, 9, 6)}
            className="ct-triangle-left"
            fill={this.props.type === "posttest" ? "none" : blueFill}
          />
          <polygon
            points={PointUtils.getTriangleRightCoords(9, 9, 6)}
            className="ct-triangle-right"
            fill={this.props.type === "pretest" ? "none" : blueFill}
          />
        </StyledSvg>
      );
    } else {
      return (
        <StyledSvg type={this.props.type} fill="none" viewBox="0 0 18 18">
          <circle
            cx="9"
            cy="9"
            r="9"
            className="ct-selected ct-dot ct-standard"
          />
        </StyledSvg>
      );
    }
  }
}
