import { Col as AntCol } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IColSize {
  span?: number;
  order?: number;
  offset?: number;
  push?: number;
  pull?: number;
}

export interface IColProps {
  /**
   * Prevent page and column breaks within the column
   */
  breakInside?: "auto" | "avoid" | "avoid-page" | "avoid-column";
  /**
   * Color
   */
  color?:
    | "primary"
    | "default"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Layout mode
   */
  type?: "flex" | "responsive";
  /**
   * Flex grow value that dictates the proportion of space inside the row.
   */
  grow?: number;
  /**
   * Flex basis value that dictates the initial width of the column.
   */
  basis?: string;
  /**
   * ≥992px, could be a span value or an object containing above props
   */
  lg?: number | IColSize;
  /**
   * Defines space around outside of column using CSS margin syntax
   */
  margin?: string;
  /**
   * ≥768px, could be a span value or an object containing above props
   */
  md?: number | IColSize;
  /**
   * Number of cells to offset Col from the left
   */
  offset?: number;
  /**
   * Callback function fired when column clicked
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Raster order, used in flex layout mode
   */
  order?: number;
  /**
   * Defines space around the column's content using CSS padding syntax
   */
  padding?: string;
  /**
   * Number of cells that raster is moved to the right
   */
  push?: number;
  /**
   * Number of cells that raster is moved to the left
   */
  pull?: number;
  /**
   * Indicates whether column contents are selectable.
   * @default true
   */
  selectable?: boolean;
  /**
   * ≥576px, could be a span value or an object containing above props
   */
  sm?: number | IColSize;
  /**
   * Raster number of cells to occupy, 0 corresponds to display: none
   */
  span?: number;
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * ≥1200px, could be a span value or an object containing above props
   */
  xl?: number | IColSize;
  /**
   * <576px and also default setting, could be a span value or an object containing above props
   */
  xs?: number | IColSize;
  /**
   * ≥1600px, could be a span value or an object containing above props
   */
  xxl?: number | IColSize;
  /**
   * Controls visibility of overlapping elements
   */
   zIndex?: number;
  }

const StyledCol = styled<React.FunctionComponent<IColProps>>(
  (props: IColProps) => {
    const newProps: any = { ...props };
    delete newProps.padding;
    delete newProps.variation;
    delete newProps.color;
    delete newProps.selectable;
    delete newProps.margin;
    delete newProps.grow;
    delete newProps.basis;
    delete newProps.type;
    delete newProps.zIndex;
    return <AntCol {...newProps} />;
  }
)`
&& {
    ${props => (props.padding ? "padding: " + props.padding + ";" : "")}
    ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
    ${props =>
      props.type === "flex" ? "display: flex; flex-flow: column;" : ""}    
    ${props => (props.grow ? "flex-grow: " + props.grow + ";" : "")}    
    ${props => (props.basis ? "flex-basis: " + props.basis + ";" : "")}    
    ${props =>
      props.breakInside ? "break-inside: " + props.breakInside + ";" : ""}
    ${props => (props.onClick ? "cursor: pointer;" : "")}
    ${props => (props.zIndex !== undefined ? "z-index: " + props.zIndex + ";" : "")}
    `;

/**
 * Column component for grid layouts.
 */
export class Col extends React.PureComponent<IColProps> {
  public render() {
    if (this.props.color) {
      let className =
        "ant-bg-" +
        this.props.color +
        (this.props.variation ? "-" + this.props.variation : "");
      if (
        this.props.selectable !== undefined &&
        this.props.selectable === false
      ) {
        className = className + " unselectable";
      }
      return <StyledCol className={className} {...this.props} />;
    } else {
      return <StyledCol {...this.props} />;
    }
  }
}
