import styled from "styled-components";

export const PrintPage = styled.div`
  width: calc(100vw - 1in);
  height: calc(100vh - 1in);
  page-break-after: always;
  display: flex;
  flex-flow: column;
  position: relative;
  top: 0.5in;
`;
