import { List as AntList } from "antd";
import * as React from "react";
import InfiniteScroller from "react-infinite-scroller";
import styled from "styled-components";

export interface IListGridType {
  gutter?: number;
  column?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  xs?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  sm?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  md?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  lg?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  xl?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  xxl?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
}

export interface IListSpinProps {
  spinning?: boolean;
  size?: "small" | "default" | "large";
  tip?: string;
  delay?: number;
  wrapperClassName?: string;
  indicator?: React.ReactElement<any>;
}

export interface IListProps {
  /**
   * Whether list has a border.
   */
  bordered?: boolean;
  /**
   * Items that make up the list.
   */
  children?: React.ReactNode;
  /**
   * List footer.
   */
  footer?: React.ReactNode;
  /**
   * Type of grid layout to apply
   */
  grid?: IListGridType;
  /**
   * List header.
   */
  header?: React.ReactNode;
  /**
   * Header color
   */
  headerColor?: "primary";
  /**
   * Layout of the list.
   * @default horizontal
   */
  itemLayout?: "horizontal" | "vertical";
  /**
   * Shows a loading indicator while the contents of the list are being fetched
   */
  loading?: boolean | IListSpinProps;
  /**
   * Shows load more content
   */
  loadMore?: React.ReactNode;
  /**
   * Size of list
   */
  size?: "small" | "default" | "large";
  /**
   * Toggles rendering of the split under the list item
   */
  split?: boolean;
  /**
   * Item rendering, required for infinite scrolling / paging
   */
  renderItem?: (item: any, index: number) => React.ReactNode;
  /**
   * Callback for loading data, required if renderItem is specified
   */
  onLoadItemData?: (page: number) => void;
  /**
   * item data source
   */
  dataSource?: any;
  /**
   * whether or not to load more
   */
  hasMore?: boolean;
}

const StyledList = styled<React.FunctionComponent<IListProps>>(props => {
  const newProps: any = { ...props };
  delete newProps.headerColor;
  delete newProps.onLoadItemData;
  delete newProps.renderItem;
  delete newProps.hasMore;
  return (
    <AntList
      renderItem={props.renderItem ? props.renderItem : ""}
      {...newProps}
    />
  );
})`
  && {
    & div.ant-list-header {
      ${props =>
        props.headerColor === "primary" ? "background-color: #397bbe;" : ""}
      ${props => (props.headerColor === "primary" ? "color: #ffffff;" : "")}
    }
  }
`;

/**
 * A list of items
 */
export class List extends React.PureComponent<IListProps> {
  constructor(props: IListProps) {
    super(props);
  }

  public render() {
    if (this.props.onLoadItemData) {
      return (
        <InfiniteScroller
          loadMore={this.props.onLoadItemData}
          useWindow={false}
          hasMore={this.props.hasMore}
          threshold={500}
        >
          <StyledList {...this.props} />
        </InfiniteScroller>
      );
    } else {
      return <StyledList {...this.props} />;
    }
  }
}
