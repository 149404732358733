import { Collapse as AntCollapse } from "antd";
import * as React from "react";

export interface ICollapseProps {
  /**
   * Toggles rendering of the border around the collapse block
   */
  bordered?: boolean;
  /**
   * If true, Collapse renders as Accordion
   */
  accordion?: boolean;
  /**
   * Key of the active panel
   */
  activeKey?: string[] | string;
  /**
   * Key of the initial active panel
   */
  defaultActiveKey?: string[];
  /**
   * Destroy Inactive Panel
   */
  destroyInactivePanel?: boolean;
  /**
   * Callback function executed when active panel is changed
   */
  onChange?: (key: string | string[]) => void;
}

/**
 * A collapsable container
 */
export class Collapse extends React.PureComponent<ICollapseProps> {
  public render() {
    return <AntCollapse {...this.props} />;
  }
}
