import { Menu as AntMenu } from "antd";
import * as React from "react";

export interface ISubMenuProps {
  /**
   * Sub menus or menu items
   */
  children?: React.ReactNode;
  /**
   * Whether sub menu is disabled or not
   */
  disabled?: boolean;
  /**
   * Unique id of the sub menu
   */
  key?: string;
  /**
   * Title of the sub menu
   */
  title?: React.ReactNode;
}

/**
 * Menu item component.
 */
export class SubMenu extends React.PureComponent<ISubMenuProps> {
  public render() {
    return <AntMenu.SubMenu {...this.props} />;
  }
}
