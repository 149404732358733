import { Alert as AntAlert } from "antd";
import * as React from "react";

export interface IAlertProps {
  /** Type of Alert styles, options: success, info, warning, error */
  type?: "success" | "info" | "warning" | "error";
  /** Whether Alert can be closed */
  closable?: boolean;
  /** Close text to show */
  closeText?: React.ReactNode;
  /** Content of Alert */
  message: React.ReactNode;
  /** Additional content of Alert */
  description?: React.ReactNode;
  /** Callback when close Alert */
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  /** Trigger when animation ending of Alert */
  afterClose?: () => void;
  /** Whether to show icon */
  showIcon?: boolean;
  /** Called when close animation is finished */
  banner?: boolean;
  /** Custom icon, effective when showIcon is true */
  icon?: React.ReactNode;
  /** The action of Alert */
  action?: React.ReactNode;
}

/**
 * An alert component for entering a number.
 */
export class Alert extends React.PureComponent<IAlertProps> {
  public render() {
    return <AntAlert {...this.props} />;
  }
}
