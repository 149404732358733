import { Input as AntInput } from "antd";
import * as React from "react";

export interface IInputEmailProps {
  /**
   * The label text displayed After (on the Right side of) the input field.
   */
  addonAfter?: string;
  /**
   * The label text displayed before (on the left side of) the input field.
   */
  addonBefore?: string;
  /**
   * Initial value
   */
  defaultValue?: string;
  /**
   * Determine whether the DatePicker is disabled
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * callback when user input
   */
  onChange?: (value: any) => void;
  /**
   * The callback function that is triggered when Enter key is pressed.
   */
  onPressEnter?: (value: any) => void;
  /**
   * Placeholder of date input
   */
  placeholder: string;
  /**
   * Precision of the input value
   */
  precision?: number;
  /**
   * The prefix icon for the Input.
   */
  prefix?: string;
  /**
   * Determine the size of the input box
   */
  size?: "large" | "small" | "default";
  /**
   * The suffix icon for the Input.
   */
  suffix?: string;
  /**
   * The type of input, see: MDN(use Input.TextArea instead of type="textarea")
   */
  type?: string;
  /**
   * Current value
   */
  value?: string;
  /**
   * ref for controlling focus
   */
  innerRef?: React.RefObject<any>;
}

/**
 * An input component for entering an email address.
 */
export class InputEmail extends React.PureComponent<IInputEmailProps> {
  public render() {
    const newProps: any = { ...this.props };
    delete newProps.innerRef;
    return <AntInput type="email" {...newProps} ref={this.props.innerRef} />;
  }
}
