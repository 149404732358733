import * as React from "react";
import { Row } from "../../Layout/Row";
import { Heading } from "../../Typography/Heading";
import Dotdotdot from "react-dotdotdot";
import { IChartPhase } from "./ChartTypes";
import styled from "styled-components";

interface IPhaseBoxProps {
  phases: IChartPhase[];
  maxPoints: number;
}

interface IPhaseWrapperProps {
  /**
   * Percentage of width of phase box to allocate to this phase.
   */
  percentWidth: number;
  /**
   * React key
   */
  key: number;
}

const PhaseBoxWrapper = styled.div<IPhaseWrapperProps>`
  display: flex;
  height: 100px;
  flex-grow: 1;
  flex-basis: ${props => props.percentWidth}%;
  flex-direction: column;
  padding: ${props => (props.key == 0 ? "0 4px 0 0" : "0 4px")};
`;

export const PhaseBox = (props: IPhaseBoxProps) => {
  let count: number = 0;
  return (
    <Row type="flex" alignItems="flex-start" wrap={true}>
      {props.phases.map((phase, index) => {
        const label = phase.labelOverride;
        const percentWidth = phase.pointsNumber;
        count += percentWidth;
        return (
          <PhaseBoxWrapper
            percentWidth={(percentWidth * 100) / props.maxPoints}
            key={index}
          >
            <Heading level={7} weight="bold">
              {phase.titleLetter}
            </Heading>
            <Dotdotdot clamp="80px">
              <Heading level={7}>{label}</Heading>
            </Dotdotdot>
          </PhaseBoxWrapper>
        );
      })}
      {props.maxPoints > count && (
        <PhaseBoxWrapper
          percentWidth={((props.maxPoints - count) * 100) / props.maxPoints}
          key={props.phases.length}
        />
      )}
    </Row>
  );
};
