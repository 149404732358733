import { Popconfirm as AntPopConfirm } from "antd";
import * as React from "react";

export interface IPopConfirmProps {
  /**
   * text of the Cancel button
   */
  cancelText?: React.ReactNode;
  /**
   * customize icon of confirmation
   */
  icon?: React.ReactNode;
  /**
   * title of the confirmation box
   */
  title: React.ReactNode;
  /**
   * text of the Confirm button
   */
  okText?: React.ReactNode;
  /**
   * callback of cancel
   */
  onCancel?: (e?: React.MouseEvent<any>) => void;
  /**
   * callback of confirmation
   */
  onConfirm?: (e?: React.MouseEvent<any>) => void;
  /**
   *	Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}
/**
 * PopConfirm component.
 */
export class PopConfirm extends React.PureComponent<IPopConfirmProps> {
  public render() {
    const getPopupContainer = this.props.getPopupContainer
      ? this.props.getPopupContainer
      : (trigger: HTMLElement) => trigger.parentElement as HTMLElement;

    return (
      <AntPopConfirm {...this.props} getPopupContainer={getPopupContainer} />
    );
  }
}
