import { DatePicker as AntDatePicker } from "antd";
import AntIcon from "@ant-design/icons";
import moment from "moment";
import * as React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

export interface IDatePickerProps {
  /**
   * Initial value
   */
  defaultValue?: moment.Moment;
  /**
   * Determine whether the DatePicker is disabled
   */
  disabled?: boolean;
  /**
   * Specify the date that cannot be selected
   */
  disabledDate?: (currentDate: moment.Moment) => boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Callback function, can be executed when the selected time is changing
   */
  onChange?: (date: moment.Moment, dateString: string) => void;
  /**
   * Placeholder of date input. Does not work on mobile devices.
   */
  placeholder: string;
  /**
   * Determine the size of the input box, the height of large and small, are 40px and 24px respectively, while default size is 32px
   */
  size?: "large" | "small";
  /**
   * Set date
   */
  value?: moment.Moment;
  /**
   *	Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}

interface INativeDatePickerProps {
  className?: string;
}

const NativeDatePicker = styled<
  React.FunctionComponent<IDatePickerProps & INativeDatePickerProps>
>(props => {
  const newProps: any = { ...props };
  delete newProps.format;
  delete newProps.size;
  delete newProps.children;
  delete newProps.disabled;
  delete newProps.value;
  const value = props.value ? props.value.format("YYYY-MM-DD") : undefined;
  return (
    <span className="ant-calendar-picker">
      <div>
        <input type="date" value={value} {...newProps} />
        <AntIcon type="calendar" className={"ant-calendar-picker-icon"} />
      </div>
    </span>
  );
})`
  min-width: 14em;
`;

/**
 * An input component for entering a date.
 */
export class DatePicker extends React.PureComponent<IDatePickerProps> {
  constructor(props: IDatePickerProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  public render() {
    if (isMobile) {
      const newProps: any = { ...this.props };
      let className = "ant-calendar-picker-input ant-input";
      if (this.props.disabled && this.props.disabled === true) {
        className += " ant-input-disabled";
      }
      if (this.props.size === "large") {
        className += " ant-input-lg";
      } else if (this.props.size === "small") {
        className += " ant-input-sm";
      }
      return (
        <NativeDatePicker
          className={className}
          {...newProps}
          onChange={this.handleChange}
        />
      );
    } else {
      const getPopupContainer = this.props.getPopupContainer
        ? this.props.getPopupContainer
        : (trigger: HTMLElement) => trigger.parentElement as HTMLElement;

      return (
        <AntDatePicker
          picker="date"
          {...this.props}
          getPopupContainer={getPopupContainer}
        />
      );
    }
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const stringToMoment = moment;
    if (this.props.onChange) {
      this.props.onChange(
        stringToMoment(event.target.value),
        event.target.value
      );
    }
  }
}
