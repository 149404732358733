import { Radio as AntRadio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import * as React from "react";

const AntRadioGroup = AntRadio.Group;

export interface IRadioGroupProps {
  /**
   * Function called on change
   */
  onChange?: (e: MouseEvent) => void;
  /**
   * style type of radio button
   */
  buttonStyle?: "outline" | "solid";
  /**
   * Label to display to the right of the radio
   */
  children?: React.ReactNode;
  /**
   * Default selected value
   */
  defaultValue?: any;
  /**
   * Disable all radio buttons
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * the name property of all input[type=radio] children
   */
  name?: string;
  /**
   * size for radio button style
   */
  size?: "large" | "default" | "small";
  /**
   * According to value for comparison, to determine whether the selected
   */
  value?: any;
}

/**
 * An input component for controlling a radio.
 */
export class RadioGroup extends React.PureComponent<IRadioGroupProps> {
  constructor(props: IRadioGroupProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  public render() {
    const newProps: any = { ...this.props };
    delete newProps.onChange;
    return <AntRadioGroup onChange={this.handleChange} {...newProps} />;
  }
  private handleChange(e: RadioChangeEvent) {
    if (this.props.onChange) {
      this.props.onChange(e.nativeEvent);
    }
  }
}
