export const PrintPointStyle = `
/**
* Data point types
*/
.ct-standard {
    fill: black;
}

.ct-border-rect {
    stroke-dasharray: 1, 8, 16;
}

.ct-border {
    stroke: black;
}

.ct-in-circle {
    fill: black;
}

.ct-triangle-left,
.ct-triangle-right {
    stroke: black;
    fill: white;
    stroke-width: 1px;
}

.ct-pretest .ct-triangle-right,
.ct-posttest .ct-triangle-left {
    stroke-dasharray: 1, 6, 21;
}

.ct-pretest.ct-outlined .ct-triangle-right,
.ct-posttest.ct-outlined .ct-triangle-left {
    stroke-dasharray: 1, 2, 7;
}

.ct-pretest .ct-triangle-left,
.ct-posttest .ct-triangle-right,
.ct-probe .ct-triangle-right,
.ct-probe .ct-triangle-left {
    fill: black;
}
`;
