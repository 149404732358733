import { Dropdown as AntDropdown } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IDropdownProps {
  /**
   * Whether the dropdown menu is disabled
   */
  disabled?: boolean;
  /**
   * Callback function takes an argument: visible, is executed when the visible state is changed
   */
  onVisibleChange?: (visible?: boolean) => void;
  /**
   * The menu to display when dropdown is triggered
   */
  overlay: React.ReactElement;
  /**
   * Placement of menu
   */
  placement?:
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight";
  /**
   * The trigger mode which executes the drop-down action
   */
  trigger?: Array<"click" | "hover" | "contextMenu">;
  /**
   * Whether the dropdown menu is visible
   */
  visible?: boolean;
  /**
   *	Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}

/**
 * A dropdown list.
 */
export const Dropdown = styled<React.FunctionComponent<IDropdownProps>>(
  props => {
    const getPopupContainer = props.getPopupContainer
      ? props.getPopupContainer
      : (trigger: HTMLElement) => trigger.parentElement as HTMLElement;
    return <AntDropdown {...props} getPopupContainer={getPopupContainer} />;
  }
)`
  && {
    ${props =>
      props.trigger && props.trigger.length == 1 && props.trigger[0] === "click"
        ? "cursor: pointer;"
        : ""}
  }
`;
