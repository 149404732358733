import { Tag as AntTag, Tooltip as AntTooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import * as React from "react";
import styled from "styled-components";

export interface ITagProps {
  /**
   * Callback executed when close animation is completed
   */
  afterClose?: () => void;
  /**
   * Whether the Tag can be closed
   */
  closable?: boolean;
  /**
   * Color of the Tag
   */
  color?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Maximum width of Tag
   */
  maxWidth?: string;
  /**
   * Callback executed when tag is closed
   */
  onClose?: () => void;
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Whether the Tag is closed or not
   */
  visible?: boolean;
  /**
   * Placement of tooltip for tag
   */
  tooltipPlacement?: TooltipPlacement;
}

const StyledAntTag = styled<React.FunctionComponent<ITagProps>>(React.forwardRef((props, ref) => {
  const newProps: any = { ...props };
  delete newProps.maxWidth;
  delete newProps.color;
  delete newProps.tooltipPlacement;
  return <AntTag ref={ref} {...newProps} />;
}))`
  && {
    ${props =>
      props.maxWidth
        ? "max-width: " +
          props.maxWidth +
          "; " +
          "overflow: hidden; " +
          "text-overflow: ellipsis; " +
          "white-space: nowrap;"
        : ""}
  `;

/**
 * Tag component for labeling and categorizing.
 */
export class Tag extends React.PureComponent<ITagProps> {
  public render() {
    const className = this.props.color
      ? {
          className:
            "ant-bg-" +
            this.props.color +
            (this.props.variation ? "-" + this.props.variation : "")
        }
      : undefined;
    if (this.props.maxWidth) {
      return (
        <AntTooltip
          {...className}
          title={this.props.children}
          placement={this.props.tooltipPlacement}
        >
          <StyledAntTag {...this.props} />
        </AntTooltip>
      );
    } else {
      return <StyledAntTag {...className} {...this.props} />;
    }
  }
}
