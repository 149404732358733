import { Radio as AntRadio } from "antd";
import * as React from "react";
import styled from "styled-components";

interface IRadioChangeEvent {
  target: { checked: boolean };
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: MouseEvent;
}

export interface IRadioProps {
  /**
   * Current state of radio.
   * @default false
   */
  checked?: boolean;
  /**
   * Label to display to the right of the radio
   */
  children?: React.ReactNode;
  /**
   * Specifies the initial state: whether or not the radio is selected.
   */
  defaultChecked?: boolean;
  /**
   * Disable radio
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * According to value for comparison, to determine whether the selected
   */
  value?: any;
  /**
   * Display as block instead of inline
   */
  block?: boolean;
  /**
   * Callback when state of radio button changes
   */
  onChange?: (e: IRadioChangeEvent) => void;
}

const StyledRadio = styled<React.FunctionComponent<IRadioProps>>(props => {
  const newProps: any = { ...props };
  delete newProps.block;
  return <AntRadio {...newProps} />;
})`
  && {
    ${props => (!!props.block ? "display: block;" : "")}
  }
`;

/**
 * An input component for controlling a radio.
 */
export const Radio = (props: IRadioProps) => {
  return <StyledRadio {...props} />;
};
