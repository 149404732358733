import { Typography } from "antd";
import * as React from "react";
import { ITypographyProps } from "./TypographyProps";
import styled from "styled-components";

interface IEllipsisProps {
  rows?: number;
  expandable?: boolean;
  suffix?: string;
  symbol?: React.ReactNode;
  tooltip?: boolean | React.ReactNode;
  onExpand?: (e: Event) => void;
  onEllipsis?: (e: IEllipsisProps) => void;
}
export interface IParagraphProps extends ITypographyProps {
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Color
   */
  color?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Display ellipsis when overflow. Can config rows and expandable by using object
   */
  ellipsis?: boolean | IEllipsisProps;
  /**
   * Controls font size
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * Maximum width of the paragraph
   */
  maxWidth?: string;
  /**
   * Defines space around outside of paragraph using CSS margin syntax.
   * default="0 0 1em 0"
   */
  margin?: string;
  /**
   * bold style
   */
  strong?: boolean;
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Insert line breaks to prevent overflow.
   */
  wrap?: boolean;
}

const StyledParagraph = styled<React.FunctionComponent<IParagraphProps>>(
  props => {
    const newProps: any = { ...props };
    delete newProps.maxWidth;
    delete newProps.wrap;
    delete newProps.margin;
    return <Typography.Paragraph {...newProps} />;
  }
)`
  && {
    ${props => (props.maxWidth ? "max-width: " + props.maxWidth + ";" : "")}
    ${props => (props.wrap ? "word-break: break-word;" : "")}
    ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
  `;

export class Paragraph extends React.PureComponent<IParagraphProps> {
  public render() {
    let className =
      this.props.level !== undefined ? `ant-heading-${this.props.level} ` : "";
    className += this.props.color
      ? "ant-fg-" +
        this.props.color +
        (this.props.variation ? "-" + this.props.variation : "")
      : "";
    return (
      <StyledParagraph className={className} {...this.props}>
        {this.props.children}
      </StyledParagraph>
    );
  }
}
