import { DatePicker as AntDatePicker } from "antd";
import * as moment from "moment";
import * as React from "react";

export interface IMonthPickerProps {
  /**
   * Whether to show clear button
   */
  allowClear?: boolean;
  /**
   * Initial value
   */
  defaultValue?: moment.Moment;
  /**
   * Determine whether the DatePicker is disabled
   */
  disabled?: boolean;
  /**
   * Specify the date that cannot be selected
   */
  disabledDate?: (currentDate: moment.Moment) => boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Set the date format, refer to moment.js
   */
  format?: string;
  /**
   * Localization configuration
   */
  locale?: any;
  /**
   * Picker panel mode
   */
  mode?: "time" | "date" | "month" | "year";
  /**
   * Callback function, can be executed when the selected time is changing
   */
  onChange?: (date: moment.Moment, dateString: string) => void;
  /**
   * Placeholder of date input
   */
  placeholder: string;
  /**
   * Determine the size of the input box, the height of large and small, are 40px and 24px respectively, while default size is 32px
   */
  size?: "large" | "small";
  /**
   * Set date
   */
  value?: moment.Moment;
}

/**
 * Input control for picking a month.
 */
export class MonthPicker extends React.PureComponent<IMonthPickerProps> {
  public render() {
    return <AntDatePicker.MonthPicker {...this.props} />;
  }
}
