import { Typography } from "antd";
import * as React from "react";
import { ITypographyProps } from "./TypographyProps";
import styled from "styled-components";

export interface ITextProps extends ITypographyProps {
  /**
   * Toggle bold
   */
  strong?: boolean;
  /**
   * Toggle italics
   */
  emphasis?: boolean;
  /**
   * Toggle underline
   */
  underline?: boolean;
  /**
   * Display ellipsis when overflow. Can config rows and expandable by using object
   */
  ellipsis?: boolean;
}

const StyledText = styled<React.FunctionComponent<ITextProps>>(props => {
  const newProps: any = { ...props };
  delete newProps.emphasis;
  return <Typography.Text {...newProps} />;
})`
  && {
    ${props => (props.emphasis ? "font-style: italic; " : "")}
`;

/**
 * Formatted text.
 */
export class Text extends React.PureComponent<ITextProps> {
  public render() {
    return <StyledText {...this.props}>{this.props.children}</StyledText>;
  }
}
