import { notification as AntNotification } from "antd";
import * as React from "react";

export interface INotificationProps {
  /**
   * Customized close button
   */
  btn?: React.ReactNode;
  /**
   * Customized CSS class
   */
  className?: string;
  /**
   * The content of notification box (required)
   */
  description: string | React.ReactNode;
  /**
   * Time in seconds before Notification is closed. When set to 0 or null, it will never be closed automatically
   */
  duration: number | null;
  /**
   * Customized icon
   */
  icon?: React.ReactNode;
  /**
   * The unique identifier of the Notification
   */
  key?: string;
  /**
   * The title of notification box (required)
   */
  message: string | React.ReactNode;
  /**
   * Position of Notification, can be one of topLeft topRight bottomLeft bottomRight
   */
  placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";

  top?: number;

  bottom?: number;

  onClose?: () => void;
}

/**
 * Notification component.
 */
export class Notification {
  public static open(props: INotificationProps) {
    AntNotification.open(props);
  }
  public static success(props: INotificationProps) {
    AntNotification.success(props);
  }
  public static error(props: INotificationProps) {
    AntNotification.error(props);
  }
  public static info(props: INotificationProps) {
    AntNotification.info(props);
  }
  public static warning(props: INotificationProps) {
    AntNotification.warning(props);
  }
  public static warn(props: INotificationProps) {
    AntNotification.warn(props);
  }
  public static close() {
    AntNotification.close("Notification Closed");
  }
  public static destroy() {
    AntNotification.destroy();
  }
}
