import { Collapse as AntCollapse } from "antd";
import * as React from "react";

export interface ICollapsePanelProps {
  /**
   * If true, panel cannot be opened or closed
   */
  disabled?: boolean;
  /**
   * Forced render of content on panel, instead of lazy rending after clicking on header
   */
  forceRender?: boolean;
  /**
   * Title of the panel
   */
  header: React.ReactNode;
  /**
   * Unique key identifying the panel from among its siblings
   */
  key: string;
  /**
   * If false, panel will not show arrow icon
   */
  showArrow?: boolean;
}

/**
 * A panel used inside a collapsable container
 */
export class CollapsePanel extends React.PureComponent<ICollapsePanelProps> {
  public render() {
    return <AntCollapse.Panel {...this.props} />;
  }
}
