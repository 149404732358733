import styled from "styled-components";

export const PrintNote = styled.div`
  && {
    width: 100%;
    border-radius: 0 3px 3px 3px;
    border: solid 1px #000000;
    position: relative;
    padding: 0 4px;
  }
  &&::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid black;
    left: -8px;

    top: -1px;
  }
  &&::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid white;
    left: -6px;

    top: 0;
  }
`;
