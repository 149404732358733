import * as React from "react";

export interface ITextListProps {
  /**
   * Indicates if items should be numbered
   */
  numbered?: boolean;
  /**
   * Items that make up the list.
   */
  children?: React.ReactNode;
}

/**
 * List to to be used with typography components
 */
export const TextList = (props: ITextListProps) => {
  const tag = props.numbered ? "ol" : "ul";
  const Component = tag as any;
  return <Component>{props.children}</Component>;
};
