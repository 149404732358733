import { Affix as AntAffix } from "antd";
import * as React from "react";

export interface IAffixProps {
  /**
   * Pixels to offset from bottom when calculating position of scroll.
   */
  offsetBottom?: number;
  /**
   * Pixels to offset from top when calculating position of scroll
   */
  offsetTop?: number;
  /**
   * Specifies the scrollable area dom node
   */
  target?: () => Window | HTMLElement | null;
  /**
   * Callback for when affix state is changed
   */
  onChange?: (affixed?: boolean) => void;
  /**
   * Content to stick
   */
  children: React.ReactElement;
}
/**
 * Used to make content stick to viewport.
 */
export class Affix extends React.PureComponent<IAffixProps> {
  public render() {
    return <AntAffix {...this.props}>{this.props.children}</AntAffix>;
  }
}
