import { Steps as AntSteps } from "antd";
import * as React from "react";

export interface IStepProps {
  /**
   * Description of the step, optional property
   */
  description?: React.ReactNode;
  /**
   * Icon of the step, optional property
   */
  icon?: React.ReactNode;
  /**
   * Callback when step is clicked
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * To specify the status. It will be automatically set by current of Steps if not configured. Optional values are: wait process finish error
   */
  status?: "wait" | "process" | "finish" | "error";
  /**
   * Disable click
   */
  disabled?: boolean;
  /**
   * Title of the step
   */
  title?: React.ReactNode;
  /**
   * Subtitle of the step
   */
  subTitle?: React.ReactNode;
}

/**
 * A single step in a Steps component
 */
export class Step extends React.PureComponent<IStepProps> {
  public render() {
    return <AntSteps.Step {...this.props} />;
  }
}
