import * as React from "react";

export interface ITextListItemProps {
  /**
   * Contents of list item.
   */
  children?: React.ReactNode;
}

/**
 * List item
 */
export const TextListItem = (props: ITextListItemProps) => {
  return <li>{props.children}</li>;
};
