import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

export interface IPrintHeaderFooterProps {
  /**
   * Height of the header/footer region
   */
  height: string;
  /**
   * Content of the header/footer region
   */
  children?: React.ReactNode;
}

interface IPrintSpacerProps {
  /**
   * Height of the header/footer region
   */
  height: string;
}

export interface IPrintProps {
  /**
   * Size of printed page
   */
  pageSize: "letter" | "A4";
  /**
   * Orientation of printed page
   */
  orientation: "portrait" | "landscape";
  /**
   * Header to print on the top of each page
   */
  header?: IPrintHeaderFooterProps;
  /**
   * Footer to print on the bottom of each page
   */
  footer?: IPrintHeaderFooterProps;

  children?: React.ReactNode;
}

const PrintHeader = styled.div<IPrintHeaderFooterProps>`
  && {
    position: fixed;
    top: 0.5in;
    left: 0.5in;
    right: 0.5in;
    height: ${props => props.height};
  }
`;

const PrintSpacer = styled.div<IPrintSpacerProps>`
  && {
    height: ${props => props.height};
  }
`;

const PrintFooter = styled.div<IPrintHeaderFooterProps>`
  && {
    position: fixed;
    bottom: 0.5in;
    left: 0.5in;
    right: 0.5in;
    width: 100%;
    height: ${props => props.height};
  }
`;

const PrintWrapper = styled.div`
  background-color: white;
  padding: 0 0.5in;
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
`;

/**
 * A layout component for rendering print output
 */
export const Print = (props: IPrintProps) => {
  return (
    <>
      <Helmet>
        <style type="text/css">{`
                    @page {
                        size: ${props.pageSize} ${props.orientation};
                        margin: 0;
                    }
                `}</style>
      </Helmet>
      <PrintWrapper>
        {props.header || props.footer ? (
          <>
            {props.header && <PrintHeader {...props.header} />}
            {props.footer && <PrintFooter {...props.footer} />}
            <table>
              {props.header && (
                <thead>
                  <tr>
                    <td>
                      <PrintSpacer height={props.header.height} />
                    </td>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                  <td>{props.children}</td>
                </tr>
              </tbody>
              {props.footer && (
                <tfoot>
                  <tr>
                    <td>
                      <PrintSpacer height={props.footer.height} />
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </>
        ) : (
          props.children
        )}
      </PrintWrapper>
    </>
  );
};
