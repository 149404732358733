import { Table as AntTable } from "antd";
import * as React from "react";
import InfiniteScroller from "react-infinite-scroller";
import { checkSizeProps, screenSizeFinder } from "../../screenSizeHandler";
import { ISpinProps } from "../Messaging/Spin";

export interface IColumnFilterItem {
  text: string;
  value: string;
  children?: IColumnFilterItem[];
}
export interface IColumnSize {
  visible?: boolean;
  width?: string | number;
  align?: "left" | "right" | "center";
}
export interface IColumnProps<T> {
  title?:
    | React.ReactNode
    | ((options: {
        filters: ITableStateFilters;
        sortOrder?: "descend" | "ascend";
      }) => React.ReactNode);
  key?: React.Key;
  dataIndex?: string;
  render?: (text: any, record: T, index: number) => React.ReactNode;
  align?: "left" | "right" | "center";
  filters?: IColumnFilterItem[];
  onFilter?: (value: any, record: T) => boolean;
  filterMultiple?: boolean;
  filterDropdown?: React.ReactNode | ((props: object) => React.ReactNode);
  filterDropdownVisible?: boolean;
  onFilterDropdownVisibleChange?: (visible: boolean) => void;
  sorter?: boolean | ((a: T, b: T, sortOrder?: "descend" | "ascend") => number);
  defaultSortOrder?: "descend" | "ascend";
  colSpan?: number;
  width?: string | number;
  className?: string;
  fixed?: boolean | ("left" | "right");
  filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode);
  filteredValue?: any[];
  sortOrder?: "descend" | "ascend";
  children?: Array<IColumnProps<T>>;
  onCellClick?: (record: T, event: any) => void;
  onCell?: (record: T) => any;
  onHeaderCell?: (props: IColumnProps<T>) => any;
  visible?: boolean;
  xs?: IColumnSize;
  sm?: IColumnSize;
  md?: IColumnSize;
  lg?: IColumnSize;
  xl?: IColumnSize;
  xxl?: IColumnSize;
}

export interface IPaginationConfig {
  position?: "top" | "bottom" | "both";
}
export interface ISelectionItem {
  key: string;
  text: React.ReactNode;
  onSelect: (key: string[]) => any;
}
export interface ISorterResult<T> {
  column: IColumnProps<T>;
  order: "descend" | "ascend";
  field: string;
  columnKey: string;
}
export interface ITableComponents {
  table?: React.ReactType;
  header?: {
    wrapper?: React.ReactType;
    row?: React.ReactType;
    cell?: React.ReactType;
  };
  body?: {
    wrapper?: React.ReactType;
    row?: React.ReactType;
    cell?: React.ReactType;
  };
}
export interface ITableStateFilters {
  [key: string]: string[];
}
export interface ITableRowSelection<T> {
  type?: "checkbox" | "radio";
  selectedRowKeys?: string[] | number[];
  onChange?: (selectedRowKeys: string[] | number[], selectedRows: T[]) => void;
  getCheckboxProps?: (record: T) => object;
  onSelect?: (
    record: T,
    selected: boolean,
    selectedRows: T[],
    nativeEvent: Event
  ) => any;
  onSelectMultiple?: (
    selected: boolean,
    selectedRows: T[],
    changeRows: T[]
  ) => void;
  onSelectAll?: (selected: boolean, selectedRows: T[], changeRows: T[]) => void;
  onSelectInvert?: (selectedRowKeys: string[] | number[]) => void;
  selections?: ISelectionItem[] | boolean;
  hideSelectAll?: boolean;
  fixed?: boolean;
  columnWidth?: string | number;
  selectWay?:
    | "onSelect"
    | "onSelectMultiple"
    | "onSelectAll"
    | "onSelectInvert";
  columnTitle?: string | React.ReactNode;
}

export interface ITableProps<T> {
  /**
   * Whether to show all table borders
   */
  bordered?: boolean;
  /**
   * The column contains children to display
   */
  childrenColumnName?: string;
  /**
   * Columns of table
   */
  columns?: Array<IColumnProps<T>>;
  /**
   * Override default table elements
   */
  components?: ITableComponents;
  /**
   * Data record array to be displayed
   */
  dataSource?: T[];
  /**
   * Expand all rows initially
   */
  defaultExpandAllRows?: boolean;
  /**
   * Initial expanded row keys
   */
  defaultExpandedRowKeys?: string[] | number[];
  /**
   * Current expanded row keys
   */
  expandedRowKeys?: string[] | number[];
  /**
   * Expanded container render for each row
   */
  expandedRowRender?: (
    record: T,
    index: number,
    indent: number,
    expanded: boolean
  ) => React.ReactNode;
  /**
   * Customize expand icon column index. Not render when -1
   */
  expandIconColumnIndex? : number,
  /**
   * Whether to expand row by clicking anywhere in the whole row
   */
  expandRowByClick?: boolean;
  /**
   * Enable row can be expandable
   */
  rowExpandable? : (record : T) => boolean,
  /**
   * Table footer renderer
   */
  footer?: (currentPageData: object[]) => React.ReactNode;
  /**
   * Indent size in pixels of tree data
   */
  indentSize?: number;
  /**
   * Loading status of table
   */
  loading?: boolean | ISpinProps;
  /**
   * i18n text including filter, sort, empty text, etc
   */
  locale?: object;
  /**
   * Callback executed when pagination, filters or sorter is changed
   * @param e
   */
  onChange?: (
    pagination: IPaginationConfig,
    filters: Record<keyof T, string[]>,
    sorter: ISorterResult<T>
  ) => void;
  /**
   * Callback executed when the row expand icon is clicked
   */
  onExpand?: (expanded: boolean, record: T) => void;
  /**
   * Callback executed when the expanded rows change
   */
  onExpandedRowsChange?: (expandedRowKeys: string[] | number[]) => void;
  /**
   * Set props on per header row
   */
  onHeaderRow?: (columns: Array<IColumnProps<T>>, index: number) => any;
  /**
   * Set props on per row
   */
  onRow?: (record: T, index: number) => any;
  /**
   * Pagination config or Pagination, hide it by setting it to false
   */
  pagination?: IPaginationConfig | false;
  /**
   * Row's className
   */
  rowClassName?: (record: T, index: number) => string;
  /**
   * Row's unique key, could be a string or function that returns a string
   */
  rowKey?: string | ((record: T, index: number) => string);
  /**
   * Row selection config
   */
  rowSelection?: ITableRowSelection<T>;
  /**
   * Set horizontal or vertical scrolling, can also be used to specify the width and height of the scroll area. It is recommended to set a number for x, if you want to set it to true, you need to add style .ant-table td { white-space: nowrap; }.
   */
  scroll?: {
    x?: boolean | number | string;
    y?: boolean | number | string;
  };
  /**
   * Whether to show table header
   */
  showHeader?: boolean;
  /**
   * Size of table
   */
  size?: "default" | "middle" | "small";
  /**
   * Table title renderer
   */
  title?: (currentPageData: object[]) => React.ReactNode;
  /**
   * Callback for loading data, required if renderItem is specified
   */
  onLoadItemData?: (page: number) => void;
  /**
   * whether or not to load more
   */
  hasMore?: boolean;
}

export interface ITableState<T> {
  screenWidth: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
}

/**
 * A table of items
 */
export class Table<T> extends React.PureComponent<
  ITableProps<T>,
  ITableState<T>
> {
  public constructor(props: ITableProps<T>) {
    super(props);
    this.state = { screenWidth: "xxl" };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  public componentDidMount() {
    if (this.props.columns!.some(column => checkSizeProps(column))) {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }
  }

  public componentWillUnmount() {
    if (this.props.columns!.some(column => checkSizeProps(column))) {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }
  }

  public render() {
    const newProps: any = { ...this.props, showSorterTooltip: false };
    delete newProps.onLoadItemData;
    delete newProps.hasMore;
    if (this.props.columns) {
      const newColumns = new Array<IColumnProps<T>>();
      for (const column of this.props.columns!) {
        const newColumn: any = { ...column };
        if (column[this.state.screenWidth]) {
          const sizeProps = column[this.state.screenWidth] as IColumnSize;
          if (sizeProps.align) {
            newColumn.align = sizeProps.align;
          }
          if (sizeProps.visible !== undefined) {
            newColumn.visible = sizeProps.visible;
          }
          if (sizeProps.width) {
            newColumn.width = sizeProps.width;
          }
        }
        if (newColumn.visible === undefined || newColumn.visible === true) {
          newColumns.push(newColumn);
        }
      }
      newProps.columns = newColumns;
    }
    if (this.props.onLoadItemData) {
      return (
        <InfiniteScroller
          loadMore={this.props.onLoadItemData}
          useWindow={false}
          hasMore={this.props.hasMore}
          threshold={1650}
        >
          <AntTable {...newProps} />
        </InfiniteScroller>
      );
    } else {
      return <AntTable {...newProps} />;
    }
  }

  private updateWindowDimensions() {
    this.setState({ screenWidth: screenSizeFinder(window.innerWidth) });
  }
}
