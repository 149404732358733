import React, { useRef, ReactNode, FC } from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;

const marqueeAnim = (offset: number) => keyframes`
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(calc(100% + ${60 - offset}px), 0);
  }
`;

const Mirror = styled.div<{
  direction: "ltr" | "rtl";
  paused: boolean;
  offset: number;
}>`
  min-width: 100%;
  flex-shrink: 0;
  animation-name: ${props => marqueeAnim(props.offset)};
  animation-direction: ${props =>
    props.direction === "rtl" ? "reverse" : "normal"};
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-play-state: ${props => (props.paused ? "paused" : "running")};
`;

const ChildContainer = styled.div<{ position: string }>`
  display: inline-block;
  position: ${props => props.position};
`;

interface IChildProps {
  children: ReactNode;
  x: number;
  y: number;
}

const Child: FC<IChildProps> = ({ children, x, y }: IChildProps) => (
  <ChildContainer
    position={"relative"}
    style={{
      left: x,
      top: y
    }}
  >
    {children}
  </ChildContainer>
);

interface IMarqueeProps {
  children: ReactNode[];
  direction: "ltr" | "rtl";
  offset: number;
  iteration: number;
  duration: number; // move x pixels per second
  onFinish: () => void;
}

interface Position {
  x: number;
  y: number;
}

export const Marquee: FC<IMarqueeProps> = (props: IMarqueeProps) => {
  const containerRef = useRef<any>(null);
  const marqueeRef = useRef<any>(null);
  const pixelOffset = (60 * props.offset) / props.duration;

  const renderChild = (
    child: ReactNode,
    index: number,
    isOriginal: boolean
  ) => {
    const pos: Position = { x: 0, y: 0 };

    return (
      <Child
        key={`${isOriginal ? "child-original" : "child-clone"}-${index}`}
        {...pos}
      >
        {child}
      </Child>
    );
  };

  const animatedStyle: React.CSSProperties = {
    animationDuration: `${props.duration - props.offset}s`,
    ...(props.duration === 0 ? { transform: "translateX(0)" } : {})
  };

  return (
    <Container className="ant-marquee" ref={containerRef}>
      <Mirror
        offset={pixelOffset}
        key={"m1-" + props.iteration}
        style={animatedStyle}
        direction={props.direction}
        paused={props.duration === 0}
        onAnimationEnd={() => {
          props.onFinish();
        }}
      >
        {props.children.map((c, i) => renderChild(c, i, false))}
      </Mirror>
      <Mirror
        offset={pixelOffset}
        key={"m2-" + props.iteration}
        ref={marqueeRef}
        style={animatedStyle}
        direction={props.direction}
        paused={props.duration === 0}
      >
        {props.children.map((c, i) => renderChild(c, i, true))}
      </Mirror>
      <Mirror
        offset={pixelOffset}
        key={"m3-" + props.iteration}
        style={animatedStyle}
        direction={props.direction}
        paused={props.duration === 0}
      >
        {props.children.map((c, i) => renderChild(c, i, false))}
      </Mirror>
    </Container>
  );
};
