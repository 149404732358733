import { Button as AntButton } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IButtonGroupProps {
  /**
   * Option to fit button group to its parent width
   */
  block?: boolean;
  /**
   * Can be set to small large or omitted
   */
  size?: "small" | "default" | "large";
}

const StyledGroup = styled<React.FunctionComponent<IButtonGroupProps>>(
  props => {
    const newProps: any = { ...props };
    delete newProps.block;
    return <AntButton.Group {...newProps} />;
  }
)`
  && {
    ${props =>
      props.block === true
        ? "width: 100%;" +
          "display: flex;" +
          "& > button {" +
          "   display: flex;" +
          "   flex-grow: 1;" +
          "   justify-content: center;" +
          // fix for ios 9 / safari
          "   display: -webkit-box;" +
          "   -webkit-box-orient: horizontal;" +
          "}"
        : ""}
  }
`;

/**
 * Button grouping component.
 */
export class ButtonGroup extends React.PureComponent<IButtonGroupProps> {
  public render() {
    return <StyledGroup {...this.props} />;
  }
}
