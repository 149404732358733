import { Input as AntInput } from "antd";
import * as React from "react";

const AntInputSearch = AntInput.Search;

export interface IInputSearchProps {
  /**
   * The label text displayed After (on the Right side of) the input field
   */
  addonAfter?: string;
  /**
   * The label text displayed before (on the left side of) the input field.
   */
  addonBefore?: string;
  /**
   * default value if none supplied
   */
  defaultValue?: string;
  /**
   * disable the search box
   */
  disabled?: boolean;
  /**
   * If to show button and what to show
   */
  enterButton?: boolean | React.ReactNode;
  /**
   * id of the element
   */
  id?: string;
  /**
   * ref for controlling focus
   */
  innerRef?: React.RefObject<any>;
  /**
   * function to fire when enter pressed
   */
  onPressEnter?: (value: any) => void;
  /**
   * Function to be executed when search fired
   */
  onSearch?: (value: any) => void;
  /**
   * Placeholder of date input
   */
  placeholder?: string;
  /**
   * Precision of the input value
   */
  precision?: number;
  /**
   * The prefix icon for the Input.
   */
  prefix?: string;
  /**
   * Determine the size of the input box
   */
  size?: "large" | "small";
  /**
   * The suffix icon for the Input.
   */
  suffix?: string;
  /**
   * The type of input, see: MDN(use Input.TextArea instead of type="textarea")
   */
  type?: string;
  /**
   * value of input
   */
  value?: string;
}

/**
 * An input component for searching a value.
 */
export class InputSearch extends React.PureComponent<IInputSearchProps> {
  public render() {
    return <AntInputSearch {...this.props} />;
  }
}
