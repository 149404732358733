import * as React from "react";
import { Col } from "../../Layout/Col";
import { Heading } from "../../Typography/Heading";
import PointUtils from "./PointUtils";
import styled from "styled-components";
import { PrintPointStyle } from "./Styles/PrintPointStyle";

const ChartLegendRow = styled.div`
  && {
    ${PrintPointStyle}
    display: flex;
    flex-direction: row;
  }
`;

export const ChartLegend = () => {
  return (
    <ChartLegendRow>
      <Col>
        <Heading level={6} weight="bold">
          Legend:
        </Heading>
      </Col>

      <Col>{getPagePointShape("pretest")}</Col>
      <Col>
        <Heading level={6} weight="bold">
          Pre Test
        </Heading>
      </Col>

      <Col>{getPagePointShape("standard")}</Col>
      <Col>
        <Heading level={6} weight="bold">
          Data Point
        </Heading>
      </Col>

      <Col>{getPagePointShape("probe")}</Col>
      <Col>
        <Heading level={6} weight="bold">
          Probe
        </Heading>
      </Col>

      <Col>{getPagePointShape("posttest")}</Col>
      <Col>
        <Heading level={6} weight="bold">
          Post Test
        </Heading>
      </Col>
    </ChartLegendRow>
  );
};

const StyledSvg = styled.svg`
  && {
    height: 25px;
    width: 25px;
    margin-left: 16px;
    margin-top: -3px;
  }
`;

function getPagePointShape(type: string) {
  if (type === "pretest" || type === "posttest" || type === "probe") {
    const className = `ct-selected ct-dot ct-${type}`;
    return (
      <StyledSvg type={type} className={className}>
        <polygon
          points={PointUtils.getOutRectLeftCoords(12, 12, 11)}
          className="ct-out-rect"
        />
        <polygon
          points={PointUtils.getOutRectRightCoords(12, 12, 11)}
          className="ct-out-rect"
        />
        <polygon
          points={PointUtils.getTriangleLeftCoords(12, 12, 6)}
          className="ct-triangle-left"
        />
        <polygon
          points={PointUtils.getTriangleRightCoords(12, 12, 6)}
          className="ct-triangle-right"
        />
      </StyledSvg>
    );
  } else {
    return (
      <StyledSvg type={type}>
        <circle
          cx="12"
          cy="12"
          r="9"
          className="ct-selected ct-dot ct-standard"
        />
      </StyledSvg>
    );
  }
}
