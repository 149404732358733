import { Input as AntInput } from "antd";
import * as React from "react";

const AntInputGroup = AntInput.Group;

export interface IInputGroupProps {
  size?: "large" | "small" | "default";
  compact?: boolean;
}

/**
 * An input component for searching a value.
 */
export class InputGroup extends React.PureComponent<IInputGroupProps> {
  public render() {
    return <AntInputGroup {...this.props} />;
  }
}
