import { Divider as AntDivider } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IDividerProps {
  /**
   * Line color
   */
  color?: string;
  /**
   * Whether line is dashed
   */
  dashed?: boolean;
  /**
   * height of the line
   */
  height?: string;
  /**
   * Position of title inside divider
   */
  orientation?: "" | "left" | "right";
  /**
   * Direction type of divider
   */
  type?: "horizontal" | "vertical";
  /**
   * Defines space around outside of divider using CSS margin syntax
   */
  margin?: string;
}

const StyledDivider = styled<React.FunctionComponent<IDividerProps>>(
  (props: any) => {
    const newProps: any = { ...props };
    delete newProps.margin;
    delete newProps.color;
    return <AntDivider {...newProps} />;
  }
)`
  && {
    ${props =>
      props.margin ? "margin: " + props.margin + ";" : "margin: 24px 0;"}
    ${props => (props.height ? "height: " + props.height + ";" : "")}
    ${props => (props.color ? "border-top-color: " + props.color + ";" : "")}
  }
`;

/**
 * A divider.
 */
export class Divider extends React.PureComponent<IDividerProps> {
  public render() {
    return <StyledDivider {...this.props} />;
  }
}
