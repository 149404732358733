import * as React from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Row } from "./Row";

export interface IIllustrationProps {
  /**
   * Defines the alternative text describing the illustration
   */
  alt?: string;
  /**
   * Number of offset pixels for the illustration image relative to the background
   */
  topOffset?: number;
  /**
   * Url of image to use for the illustration
   */
  src: string;
}

const StyledImage = styled.img<IIllustrationProps>`
  && {
    height: 147px;
    z-index: 5000;
    position: relative;
    margin-top: ${props => props.topOffset ?? 16}px;
    margin-bottom: -${props => props.topOffset ?? 16}px;
  }
`;

const StyledGrid = styled.div`
  && {
    z-index: 1;
    position: relative;
    background-color: rgba(9, 109, 217, 0.1);
    height: 147px;
  }
`;

/**
 * Used to present an image overlaying a background that can extend responsively.
 */
export class Illustration extends React.PureComponent<IIllustrationProps> {
  public render() {
    return (
      <StyledGrid>
        <Row type="flex" justify="center">
          <Col>
            <StyledImage {...this.props} />
          </Col>
        </Row>
      </StyledGrid>
    );
  }
}
