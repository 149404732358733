import * as React from "react";
import { Icon } from "../Data/Icon";
import { Col } from "../Layout/Col";
import { Row } from "../Layout/Row";

export interface ILinkProps {
  /**
   * URL to navigate to when link is clicked.
   */
  href?: string;
  /**
   * Type of Icon to display
   */
  icon?: string;
  /**
   * Icon's positioning to link
   */
  iconPosition?: "left" | "right";
  /**
   * Function to fire when link is clicked
   */
  onClick?: () => void;
}

/**
 * A hypertext link.
 */
export class Link extends React.PureComponent<ILinkProps> {
  public render() {
    const href = this.props.href ? { href: this.props.href } : null;
    const onClick = this.props.onClick ? { onClick: this.props.onClick } : null;
    const classNames =
      "ant-dropdown-link" + (href === null ? " ant-dropdown-trigger" : "");
    return this.props.icon ? (
      <div {...onClick}>
        <a className={classNames} {...href}>
          <Row type="flex">
            {this.props.iconPosition !== "right" && (
              <Col margin="0 8px 0 0">
                <Icon type={this.props.icon} />
              </Col>
            )}
            <Col>{this.props.children}</Col>
            {this.props.iconPosition === "right" && (
              <Col margin="0 0 0 8px">
                <Icon type={this.props.icon} />
              </Col>
            )}
          </Row>
        </a>
      </div>
    ) : (
      <a className={classNames} {...onClick} {...href}>
        {this.props.children}
      </a>
    );
  }
}
