export enum ScreenSizesEnum {
  SMALL = 576,
  MEDIUM = 768,
  LARGE = 992,
  EXTRA_LARGE = 1200,
  EXTRA_EXTRA_LARGE = 1600
}

export function screenSizeFinder(windowSize: number) {
  if (windowSize >= ScreenSizesEnum.EXTRA_EXTRA_LARGE) {
    return "xxl";
  } else if (windowSize >= ScreenSizesEnum.EXTRA_LARGE) {
    return "xl";
  } else if (windowSize >= ScreenSizesEnum.LARGE) {
    return "lg";
  } else if (windowSize >= ScreenSizesEnum.MEDIUM) {
    return "md";
  } else if (windowSize >= ScreenSizesEnum.SMALL) {
    return "sm";
  } else {
    return "xs";
  }
}

export function checkSizeProps(size: any) {
  if (
    size.xs !== undefined ||
    size.sm !== undefined ||
    size.md !== undefined ||
    size.lg !== undefined ||
    size.xl !== undefined ||
    size.xxl !== undefined
  ) {
    return true;
  } else {
    return false;
  }
}
