import { Radio as AntRadio } from "antd";
import * as React from "react";

const AntRadioButton = AntRadio.Button;

export interface IRadioButtonProps {
  /**
   * Current state of radio.
   * @default false
   */
  checked?: boolean;
  /**
   * Label to display to the right of the radio
   */
  children?: React.ReactNode;
  /**
   * Specifies the initial state: whether or not the radio is selected.
   */
  defaultChecked?: boolean;
  /**
   * Disable radio
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * According to value for comparison, to determine whether the selected
   */
  value?: any;
}

/**
 * An input component for controlling a radio.
 */
export class RadioButton extends React.PureComponent<IRadioButtonProps> {
  public render() {
    const newProps: any = { ...this.props };
    delete newProps.onChange;
    return <AntRadioButton {...newProps} />;
  }
}
