import { Menu as AntMenu } from "antd";
import * as React from "react";

/**
 * Menu divider component.
 */
export class MenuDivider extends React.PureComponent {
  public render() {
    return <AntMenu.Divider {...this.props} />;
  }
}
