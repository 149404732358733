import { Layout as AntLayout } from "antd";
import * as React from "react";

export interface ILayoutSiderProps {
  /**
   * Wether layout contains Sider in children, don't have to assign it normally.
   */
  hasSider?: boolean;
}
/**
 * Layout side component.
 */
export class LayoutSider extends React.PureComponent<ILayoutSiderProps> {
  public render() {
    return <AntLayout.Sider {...this.props} />;
  }
}
