import styled from "styled-components";

interface IPrintFrameProps {
  /**
   * URL to route within app that renders print content
   */
  src: string;
  /**
   * Size of printed page
   */
  pageSize: "letter" | "A4";
  /**
   * Orientation of printed page
   */
  orientation: "portrait" | "landscape";

  onLoad?: () => void;
}

function getHeight(
  pageSize: "letter" | "A4",
  orientation: "portrait" | "landscape"
): string {
  if (orientation == "landscape") {
    return getWidth(pageSize, "portrait");
  }
  switch (pageSize) {
    case "letter":
      return "11in";
    case "A4":
      return "297mm";
    default:
      return "100%";
  }
}

function getWidth(
  pageSize: "letter" | "A4",
  orientation: "portrait" | "landscape"
): string {
  if (orientation == "landscape") {
    return getHeight(pageSize, "portrait");
  }
  switch (pageSize) {
    case "letter":
      return "8.5in";
    case "A4":
      return "210mm";
    default:
      return "100%";
  }
}

/**
 * A layout container for printing
 */
export const PrintFrame = styled.iframe<IPrintFrameProps>`
  && {
    visibility: hidden;
    height: ${props => getHeight(props.pageSize, props.orientation)};
    width: ${props => getWidth(props.pageSize, props.orientation)};
  }
`;
