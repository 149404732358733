import { Card as AntCard } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface ICardTabListType {
  key: string;
  tab: React.ReactNode;
  disabled?: boolean;
}

interface IAntCardProps {
  /**
   * The action list, shows at the bottom of the Card.
   */
  actions?: React.ReactNode[];
  /**
   * Current TabPane's key
   */
  activeTabKey?: string;
  /**
   * Toggles rendering of the border around the card
   */
  bordered?: boolean;
  /**
   * Content of card
   */
  children?: React.ReactNode;
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Card cover
   */
  cover?: React.ReactNode;
  /**
   * Initial active TabPane's key, if activeTabKey is not set.
   */
  defaultActiveTabKey?: string;
  /**
   * Content to render in the top-right corner of the card
   */
  extra?: React.ReactNode;
  /**
   * Lift up when hovering card
   */
  hoverable?: boolean;
  /**
   * Shows a loading indicator while the contents of the card are being fetched
   */
  loading?: boolean;
  /**
   * Callback when tab is switched
   */
  onTabChange?: (key: string) => void;
  /**
   * Indicates whether card contents are selectable.
   * @default true
   */
  selectable?: boolean;
  /**
   * List of TabPane's head.
   */
  tabList?: ICardTabListType[];
  /**
   * Card title
   */
  title?: React.ReactNode;
  /**
   * Card style type, can be set to inner or not set
   */
  type?: "inner";
  /**
   *
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
}

interface INewCardProps {
  /**
   * distance from bottom of card
   */
  bottom?: string;
  /**
   * Shadow of the card
   */
  boxShadow?: string;
  /**
   * distance from left of page
   */
  left?: string;
  /**
   * distance from top of card
   */
  top?: string;
  /**
   * Background color of card
   */
  color?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Defines space around outside of card using CSS margin syntax
   */
  margin?: string;
  /**
   * Defines space around the card's content using CSS padding syntax
   */
  padding?: string;
  /**
   * How the grid should be displayed
   */
  position?: "static" | "relative" | "absolute" | "fixed" | "sticky";
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Defines the width of the card
   */
  width?: string;
  /**
   * Defines the minimum width of the card
   */
  minWidth?: string;
  /**
   * Placement of the overlay within the layers of the page
   */
  zIndex?: number;
}

export type ICardProps = IAntCardProps & INewCardProps;

const StyledCard = styled<React.FunctionComponent<ICardProps>>(props => {
  const {
    // Thread custom props that should not be passed to ant component
    padding,
    margin,
    position,
    bottom,
    left,
    top,
    color,
    selectable,
    boxShadow,
    width,
    minWidth,
    variation,
    zIndex,
    // Ant props
    ...rest
  }: ICardProps = props;

  return <AntCard size="small" {...rest} />;
})`
&& {
    & > div.ant-card-body {
      ${props => (props.padding ? "padding: " + props.padding + ";" : "")}
    }
    ${props =>
      props.margin ? "margin: " + props.margin + ";" : "margin: 12px 0;"}
    ${props => (props.boxShadow ? "box-shadow: " + props.boxShadow + ";" : "")}
    ${props => (props.zIndex ? "z-index: " + props.zIndex + ";" : "")}
    ${props => (props.width ? "width: " + props.width + ";" : "")}
    ${props => (props.minWidth ? "min-width: " + props.minWidth + ";" : "")}
    ${props =>
      (props.bordered === true || props.bordered === undefined) &&
      props.color === "primary"
        ? "border: solid 1px #003a8c;"
        : ""}
    ${props => (props.position ? "position: " + props.position + ";" : "")}
    ${props => (props.top ? "top: " + props.top + ";" : "")}
    ${props => (props.bottom ? "bottom: " + props.bottom + ";" : "")}
    ${props => (props.left ? "left: " + props.left + ";" : "")}
    ${props =>
      (props.bordered === true || props.bordered === undefined) &&
      props.color === "primary"
        ? "border-radius: 4px;"
        : ""}
}
`;

/**
 * A simple rectangular container
 */
export class Card extends React.PureComponent<ICardProps> {
  public render() {
    const color = this.props.color ? this.props.color! : "default";
    let className =
      "ant-bg-" +
      color +
      (this.props.variation ? "-" + this.props.variation : "");
    if (
      this.props.selectable !== undefined &&
      this.props.selectable === false
    ) {
      className = className + " unselectable";
    }
    return <StyledCard className={className} {...this.props} />;
  }
}
