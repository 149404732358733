import { Checkbox as AntCheckbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import * as React from "react";

export interface ICheckboxProps {
  /**
   * Current state of checkbox.
   * @default false
   */
  checked?: boolean;
  /**
   * Label to display to the right of the checkbox
   */
  children?: React.ReactNode;
  /**
   * Specifies the initial state: whether or not the checkbox is selected.
   */
  defaultChecked?: boolean;
  /**
   * Disable checkbox
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Indeterminate checked state of checkbox
   */
  indeterminate?: boolean;
  /**
   * Callback when checkbox is toggled
   */
  onChange?: (e: MouseEvent) => void;
}

/**
 * An input component for controlling a boolean flag.
 */
export class Checkbox extends React.PureComponent<ICheckboxProps> {
  constructor(props: ICheckboxProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  public render() {
    const newProps: any = { ...this.props };
    delete newProps.onChange;
    return <AntCheckbox onChange={this.handleChange} {...newProps} />;
  }
  private handleChange(e: CheckboxChangeEvent) {
    if (this.props.onChange) {
      this.props.onChange(e.nativeEvent);
    }
  }
}
