import { Form as AntForm, FormInstance } from "antd";
import * as React from "react";

export interface IFormValidateError<Values = any> {
  values: Values;
  errorFields: {
    name: (string | number)[];
    errors: string[];
  }[];
  outOfDate: boolean;
}

export interface IFormProps<Values = any> {
  /**
   * Form control instance from useForm
   */
  form?: FormInstance<Values>;
  /**
   * Hide required mark of all form items
   */
  hideRequiredMark?: boolean;
  /**
   * Initial form values
   */
  initialValues?: { [name: string]: any };
  /**
   * Define form layout
   */
  layout?: "horizontal" | "inline" | "vertical";
  /**
   * Mark required fields
   */
  requiredMark?: boolean;
  /**
   * Scroll first field with validation error to view
   */
  scrollToFirstError?: boolean;
  /**
   * Defines a function will be called if form data validation is successful.
   */
  onFinish?: (values: Values) => void;
  /**
   * Defines a function will be called if form data validation fails.
   */
  onFinishFailed?: (errorInfo: IFormValidateError<Values>) => void;
}

/**
 * Data entry form.
 */
export class Form extends React.PureComponent<IFormProps> {
  public static useForm<Values = any>() {
    return AntForm.useForm<Values>();
  }

  public static create(/* options?: FormCreateOption<{}> | undefined */) {
    return AntForm.create();
  }
  public render() {
    return <AntForm {...this.props} />;
  }
}
