import React, { useCallback, useMemo, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ITableComponents, ITableProps, Table } from "./Table";

export interface IDragTableProps<T> extends ITableProps<T> {
  onMove: (draggingIndex: number, hoverIndex: number) => void;
}

export function DragTable<T>({
  components,
  onMove,
  onRow,
  ...props
}: IDragTableProps<T>) {
  const dragComponents: ITableComponents = useMemo(() => {
    if (process.env.NODE_ENV === "development" && components?.body?.row) {
      console.warn("DragTable: components.body.row prop will be replaced");
    }

    // replace row in body if its defined
    const body = {
      ...components?.body,
      row: DragRow
    };

    // merge components with new body
    return {
      ...components,
      body
    };
  }, [components]);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      onMove(dragIndex, hoverIndex);
    },
    [onMove]
  );

  const dragOnRow = useCallback(
    (record, index) => {
      // merge onRow props with ones required for drag and drop
      return {
        ...onRow?.(record, index),
        index,
        moveRow
      };
    },
    [moveRow, onRow]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table {...props} components={dragComponents} onRow={dragOnRow} />
    </DndProvider>
  );
}

const DRAG_ROW = "DragRow";

interface DragRowItem {
  index: number;
}

interface DragRowItemProps {
  isOver?: boolean;
  dragIndex?: number;
}

// TODO: is there a way to not punt so hard on the typing
const DragRow = ({ className, index, moveRow, style, ...props }: any) => {
  const ref = useRef<HTMLTableRowElement>();

  const [{ isOver, dragIndex }, drop] = useDrop<
    DragRowItem,
    unknown,
    DragRowItemProps
  >({
    accept: DRAG_ROW,
    collect: monitor => {
      const item = monitor.getItem() as DragRowItem | null;

      if (item && item.index !== index) {
        return {
          isOver: monitor.isOver(),
          dragIndex: item.index
        };
      }

      return {};
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag<DragRowItem, unknown, unknown>({
    type: DRAG_ROW,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const mergedClassName = useMemo(() => {
    if (isOver && dragIndex !== undefined) {
      return `${className} ${
        dragIndex < index ? "drop-over-downward" : "drop-over-upward"
      }`;
    }

    return className;
  }, [className, isOver, dragIndex]);

  const mergedStyle = useMemo(() => {
    return {
      cursor: "grab",
      ...style
    };
  }, [style]);

  drop(drag(ref));

  return (
    <tr ref={ref} className={mergedClassName} style={mergedStyle} {...props} />
  );
};
