import { InputNumber as AntInputNumber } from "antd";
import * as React from "react";

export interface IInputNumberProps {
  /**
   * Option to size input element width to its parent width
   */
  block?: boolean;
  /**
   * Decimal separator
   */
  decimalSeparator?: string;
  /**
   * Initial value
   */
  defaultValue?: number;
  /**
   * Determine whether the input element is disabled
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Maximum value allowed
   */
  max?: number;
  /**
   * Minimum value allowed
   */
  min?: number;
  /**
   * Enables number keypad on mobile devices.
   * Use only when input values are natural numbers (0, 1, 2, ...)
   */
  forceKeypad?: boolean;
  /**
   * Callback function, can be executed when the value is changing
   */
  onChange?: (value: string | number | undefined) => void;
  /**
   * Placeholder of input eleement
   */
  placeholder: string;
  /**
   * Precision of the input value
   */
  precision?: number;
  /**
   * Determine the size of the input box
   */
  size?: "large" | "small";
  /**
   * The number to which the current value is increased or decreased.
   */
  step?: number;
  /**
   * Current value
   */
  value?: number;
}

/**
 * An input component for entering a number.
 */
export class InputNumber extends React.PureComponent<IInputNumberProps> {
  public render() {
    const newProps: any = { ...this.props };
    if (!!this.props.block) {
      delete newProps.block;
      newProps.style = { width: "100%" };
    }
    if (this.props.forceKeypad && this.props.forceKeypad === true) {
      delete newProps.forceKeypad;
      return <AntInputNumber pattern="\d*" {...newProps} />;
    } else {
      return <AntInputNumber {...newProps} />;
    }
  }
}
