import { Tooltip as AntTooltip } from "antd";
import * as React from "react";

export interface ITooltipProps {
  /**
   * Content that tirggers display of the tooltip
   */
  children?: React.ReactNode;
  /**
   * Tooltip to display
   */
  title: React.ReactNode | (() => React.ReactNode);
}

/**
 * A component that displays a title when mouse hovers over its content.
 */
export class Tooltip extends React.PureComponent<ITooltipProps> {
  public render() {
    return <AntTooltip {...this.props} />;
  }
}
