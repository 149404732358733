import { Tabs as AntTabs } from "antd";
import * as React from "react";

export interface ITabPaneProps {
  closable?: boolean;
  /**
   * Disables the tab
   */
  disabled?: boolean;
  /**
   * Forced render of content in tabs, not lazy render after clicking on tabs
   */
  forceRender?: boolean;
  /**
   * TabPane's key
   */
  key?: string;
  /**
   * Show text in TabPane's head
   */
  tab?: React.ReactNode | string;
}

/**
 * A tab within the Tabs component.
 */
export class TabPane extends React.PureComponent<ITabPaneProps> {
  public render() {
    return <AntTabs.TabPane {...this.props} />;
  }
}
