import Chartist, { IChartistSvg } from "chartist";
import classNames from "classnames";
import { IChartInfo } from "./ChartTypes";
import PointUtils from "./PointUtils";

export function getPointShape(
  dataPoint: IChartInfo | null,
  data: any,
  viewType = "web",
  pointClasses = ""
): IChartistSvg & { _node?: HTMLElement } {
  let _;
  let sizes;
  let pointShape;
  if (dataPoint && !dataPoint.note) {
    if (
      dataPoint.type === "pretest" ||
      dataPoint.type === "posttest" ||
      dataPoint.type === "probe"
    ) {
      sizes = viewType === "web" ? [11, 6] : [8, 4];
      pointClasses = classNames(pointClasses, "ct-dot", `ct-${dataPoint.type}`);
      pointShape = new Chartist.Svg("svg", undefined, pointClasses);
      _ = new Chartist.Svg(
        "polygon",
        {
          points: [PointUtils.getOutRectLeftCoords(data.x, data.y, sizes[0])]
        },
        "ct-out-rect",
        pointShape
      );
      _ = new Chartist.Svg(
        "polygon",
        {
          points: [PointUtils.getOutRectRightCoords(data.x, data.y, sizes[0])]
        },
        "ct-out-rect",
        pointShape
      );
      _ = new Chartist.Svg(
        "polygon",
        {
          points: [PointUtils.getTriangleLeftCoords(data.x, data.y, sizes[1])]
        },
        "ct-triangle-left",
        pointShape
      );
      _ = new Chartist.Svg(
        "polygon",
        {
          points: [PointUtils.getTriangleRightCoords(data.x, data.y, sizes[1])]
        },
        "ct-triangle-right",
        pointShape
      );

      return pointShape;
    }

    sizes = viewType === "web" ? [9] : [6];
    pointClasses = classNames(pointClasses, "ct-dot", "ct-standard");
    return new Chartist.Svg(
      "circle",
      {
        cx: [data.x],
        cy: [data.y],
        r: sizes
      },
      pointClasses
    );
  }

  if (
    dataPoint &&
    (dataPoint.type === "pretest" ||
      dataPoint.type === "posttest" ||
      dataPoint.type === "probe")
  ) {
    sizes = viewType === "web" ? [12, 9, 5] : [9, 5, 2];
    pointClasses = classNames(
      pointClasses,
      "ct-dot",
      "ct-outlined",
      `ct-${dataPoint.type}`
    );
    pointShape = new Chartist.Svg("svg", undefined, pointClasses);
    _ = new Chartist.Svg(
      "polygon",
      {
        points: [PointUtils.getOutRectLeftCoords(data.x, data.y, sizes[0])]
      },
      "ct-out-rect",
      pointShape
    );
    _ = new Chartist.Svg(
      "polygon",
      {
        points: [PointUtils.getOutRectRightCoords(data.x, data.y, sizes[0])]
      },
      "ct-out-rect",
      pointShape
    );
    _ = new Chartist.Svg(
      "polygon",
      {
        points: [PointUtils.getTriangleLeftCoords(data.x, data.y, sizes[1])]
      },
      "ct-border ct-border-rect",
      pointShape
    );
    _ = new Chartist.Svg(
      "polygon",
      {
        points: [PointUtils.getTriangleRightCoords(data.x, data.y, sizes[1])]
      },
      "ct-border ct-border-rect",
      pointShape
    );
    _ = new Chartist.Svg(
      "polygon",
      {
        points: [PointUtils.getTriangleLeftCoords(data.x, data.y, sizes[2])]
      },
      "ct-triangle-left",
      pointShape
    );
    _ = new Chartist.Svg(
      "polygon",
      {
        points: [PointUtils.getTriangleRightCoords(data.x, data.y, sizes[2])]
      },
      "ct-triangle-right",
      pointShape
    );
    _ = _;

    return pointShape;
  }

  sizes = viewType === "web" ? [9, 6] : [5, 3];
  pointClasses = classNames(
    pointClasses,
    "ct-dot",
    "ct-standard",
    "ct-outlined"
  );
  pointShape = new Chartist.Svg("svg", undefined, pointClasses);
  _ = new Chartist.Svg(
    "circle",
    {
      cx: [data.x],
      cy: [data.y],
      r: [sizes[0]]
    },
    "ct-out-circle",
    pointShape
  );
  _ = new Chartist.Svg(
    "circle",
    {
      cx: [data.x],
      cy: [data.y],
      r: [sizes[0]]
    },
    "ct-border",
    pointShape
  );
  _ = new Chartist.Svg(
    "circle",
    {
      cx: [data.x],
      cy: [data.y],
      r: [sizes[1]]
    },
    "ct-in-circle",
    pointShape
  );
  _ = _;

  return pointShape;
}
