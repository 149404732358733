import * as React from "react";
import styled from "styled-components";
import { blue300, grey300 } from "../../../Constants";
import { Icon } from "../Icon";
import { IChartPhase } from "./ChartTypes";
import { Modal } from "../../Messaging/Modal";
import { Link } from "../../Navigation/Link";
import _ from "lodash";

interface IPhaseBarProps {
  phases: IChartPhase[];
  onPhaseClick: (id: string) => void;
}

interface IPhaseBarSegmentProps {
  label: string;
  type: "selected" | "dim" | null;
  pointsCount: number;
  phase: IChartPhase;
  selected: boolean;
}

const PhaseBarContainer = styled.div`
  height: 30px;
  line-height: 30px;
  font-size: 40px;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
`;
const PhaseBarLabel = styled.div<IPhaseBarSegmentProps>`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  height: 30px;
  width: ${props =>
    (props.pointsCount === 0 ? 6 : props.pointsCount) * 52 - 34}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const PhaseBarSegmentBase = styled.div<IPhaseBarSegmentProps>`
  font-size: 15px;
  color: white;
  margin: 0 6px 0 0;
  border-color: ${grey300};
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  background-color: ${blue300};
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  height: 30px;
  width: ${props => props.pointsCount * 52 - 6}px;
  min-width: ${props =>
    (props.pointsCount === 0 ? 6 : props.pointsCount) * 52 - 6}px;
  padding: 0 5px;

  ${props =>
    props.selected &&
    `
  background-color: ${blue300};
  `}

  ${props =>
    !props.selected &&
    `
  background-color: transparent;
  color: rgba(0,0,0,0.7);
  border: 1px solid ${grey300}
  `}

  .title {
    height: 100%;
  }

  .circle-icon {
    font-size: 15px;
    ${props =>
      !props.selected &&
      `
        color: rgba(0,0,0,0.7);
    `}
  }
`;
const PhaseBarSegment = (props: IPhaseBarSegmentProps) => {
  return (
    <PhaseBarSegmentBase {...props}>
      <Icon type="fa-bullseye" />
      <PhaseBarLabel {...props}>
        &#160;
        {props.label}
      </PhaseBarLabel>
    </PhaseBarSegmentBase>
  );
};

export const PhaseBar = (props: IPhaseBarProps) => {
  const [modalPhaseId, setModalPhaseId] = React.useState<string>();
  const [selectedPhaseId, setSelectedPhaseId] = React.useState<string>();
  const handleClick = (id: string, index: number) => {
    setSelectedPhaseId(id);
    props.onPhaseClick(id);
    if (modalPhaseId === undefined) {
      setModalPhaseId(id);
    }
  };
  return (
    <PhaseBarContainer>
      {props.phases.map((phase, index) => {
        const selected = phase.id === selectedPhaseId;
        const visible = phase.id === modalPhaseId;
        return (
          <Link
            key={_.first(phase.points)?.id || phase.id}
            onClick={() => handleClick(phase.id, index)}
          >
            <PhaseBarSegment
              label={phase.target || ""}
              type={null}
              pointsCount={phase.points.length * 2}
              phase={phase}
              selected={selected}
            />
            {selected && (
              <Modal
                onCancel={() => setModalPhaseId(undefined)}
                title={phase.target || ""}
                centered={true}
                visible={visible}
                footer={null}
              >
                {phase.popover}
              </Modal>
            )}
          </Link>
        );
      })}
    </PhaseBarContainer>
  );
};
