import { Select } from "antd";
import * as React from "react";

export interface ISelectOptionProps {
  /**
   * Disable this option
   */
  disabled?: boolean;
  /**
   * Default to filter with this property
   */
  value: string | number;
  /**
   * Title of Select after select this Option
   */
  title?: string;
  /**
   * Same usage as value. If React request you to set this property, you can set it
   * to value of option, and then omit value property.
   */
  key?: string;

  children?: React.ReactNode;
}

export const SelectOption: React.FC<ISelectOptionProps> = props => {
  return <Select.Option {...props}>{props.children}</Select.Option>;
};
