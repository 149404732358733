import { Upload as AntUpload } from "antd";
import { RcFile } from "antd/lib/upload";
import * as React from "react";

export interface IUploadFile {
  uid: string;
  size: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  status?: "error" | "success" | "done" | "uploading" | "removed";
  percent?: number;
  thumbUrl?: string;
  originFileObj: RcFile;
  response?: any;
  error?: any;
  linkProps?: any;
  type: string;
}

export interface IUploadChangeParam {
  file: IUploadFile;
  fileList: IUploadFile[];
  event?: { percent: number };
}

export interface IHttpRequestHeader {
  [key: string]: string;
}

export interface IUploadProps {
  /**
   * File types that can be accepted.
   */
  accept?: string;
  /**
   * Uploading URL
   */
  action?: string | ((file: RcFile) => PromiseLike<any>);
  /**
   * Support uploading whole directory
   */
  directory?: boolean;
  /**
   * Disable upload button
   */
  disabled?: boolean;
  /**
   * Built-in stylesheet for different types of file lists
   */
  listType?: "text" | "picture" | "picture-card";
  /**
   * The callback function that is triggered when Enter key is pressed.
   */
  multiple?: boolean;
  /**
   * The name of uploading file
   */
  name?: string;
  /**
   * Whether to show default upload list
   */
  showUploadList?: boolean;
  /**
   * Need to be turned on while the server side is rendering
   */
  supportServerRender?: boolean;
  /**
   * Upload type
   */
  uploadType?: "drag" | "select";
  /**
   * Ajax upload with cookie sent
   */
  withCredentials?: boolean;
  /**
   * Click open file dialog.
   */
  openFileDialogOnClick?: boolean;
  /**
   * A callback function, can be executed when uploading state is changing
   */
  onChange?: (info: IUploadChangeParam) => void;
  /**
   * A callback function, will be executed when file link or preview icon is clicked
   */
  onPreview?: (file: IUploadFile) => void;
  /**
   * A callback function, will be executed when removing file button is clicked, remove event
   * will be prevented when return value is false or a Promise which resolve(false) or reject
   */
  onRemove?: (file: IUploadFile) => void | boolean;
  /**
   * Default list of files that have been uploaded.
   */
  defaultFileList?: IUploadFile[];
  /**
   * List of files that have been uploaded (controlled). Here is a common issue #2423 when using it
   */
  fileList?: IUploadFile[];
  /**
   * Uploading params or function which can return uploading params.
   */
  data?: object | ((file: IUploadFile) => any);
  /**
   * Set request headers, valid above IE10.
   */
  headers?: IHttpRequestHeader;
  /**
   * Maximum number of files in list
   */
  maxCount?: number;
  /**
   * Override for the default xhr behavior allowing for additional customization and ability to
   * implement your own XMLHttpRequest
   */
  customRequest?: (option: any) => void;

  beforeUpload?: (file: RcFile, FileList: RcFile[]) => boolean | string | Promise<void | Blob | File>;
}

/**
 * An input component for uploading files.
 */
export class Upload extends React.PureComponent<IUploadProps> {
  public render() {
    return <AntUpload {...this.props} />;
  }
}
