import { Layout as AntLayout } from "antd";
import * as React from "react";

export interface ILayoutFooterProps {
  /**
   * Wether layout contains Sider in children, don't have to assign it normally.
   */
  hasSider?: boolean;
}
/**
 * Layout footer component.
 */
export class LayoutFooter extends React.PureComponent<ILayoutFooterProps> {
  public render() {
    return <AntLayout.Footer {...this.props} />;
  }
}
