import { message as AntMessage } from "antd";
import * as React from "react";

export interface IMessageProps {
  /**
   * Content of the message
   */
  content?: string | React.ReactNode;
  /**
   * Time in seconds before Message is closed. When set to 0 or null, it will never be closed automatically
   */
  duration?: number;
  /**
   * Specify a function that will be called when the message is closed
   */
  onClose?: () => void;
}

/**
 * Message component.
 */
export class Message {
  public static success(props: IMessageProps) {
    AntMessage.success(props.content, props.duration, props.onClose);
  }
  public static error(props: IMessageProps) {
    AntMessage.error(props.content, props.duration, props.onClose);
  }
  public static info(props: IMessageProps) {
    AntMessage.info(props.content, props.duration, props.onClose);
  }
  public static warning(props: IMessageProps) {
    AntMessage.warning(props.content, props.duration, props.onClose);
  }
  public static loading(props: IMessageProps) {
    AntMessage.loading(props.content, props.duration, props.onClose);
  }
  public static warn(props: IMessageProps) {
    AntMessage.warn(props.content, props.duration, props.onClose);
  }
  public static destroy() {
    AntMessage.destroy();
  }
}
