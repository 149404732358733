import { Layout as AntLayout } from "antd";
import * as React from "react";

export interface ILayoutContentProps {
  /**
   * Wether layout contains Sider in children, don't have to assign it normally.
   */
  hasSider?: boolean;
}
/**
 * Layout content component.
 */
export class LayoutContent extends React.PureComponent<ILayoutContentProps> {
  public render() {
    return <AntLayout.Content {...this.props} />;
  }
}
