import { Input as AntInput } from "antd";
import * as React from "react";
import styled from "styled-components";

interface IAntInputProps {
  /**
   * The label text displayed After (on the Right side of) the input field.
   */
  addonAfter?: string;
  /**
   * The label text displayed before (on the left side of) the input field.
   */
  addonBefore?: string;
  /**
   * Initial value
   */
  defaultValue?: string;
  /**
   * Determine whether the DatePicker is disabled
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Maximum length of input string
   */
  maxLength?: number;
  /**
   * callback when user input
   */
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * The callback function that is triggered when Enter key is pressed.
   */
  onPressEnter?: (value: any) => void;
  /**
   * Placeholder of date input
   */
  placeholder?: string;
  /**
   * Precision of the input value
   */
  precision?: number;
  /**
   * The prefix icon for the Input.
   */
  prefix?: string;
  /**
   * Determine the size of the input box
   */
  size?: "large" | "small";
  /**
   * The suffix icon for the Input.
   */
  suffix?: string;
  /**
   * The type of input, see: MDN(use Input.TextArea instead of type="textarea")
   */
  type?: string;
  /**
   * Current value
   */
  value?: string;
  /**
   * Accepted file types for type='file'
   */
  accept?: string;
}

interface INewInputProps {
  /**
   * ref for controlling focus
   */
  innerRef?: React.RefObject<any>;
  /**
   * Width of input box
   */
  width?: string;
  /**
   * RegEx expression that restricts allowed input
   */
  mask?: string;
}

export type IInputProps = IAntInputProps & INewInputProps;

/**
 * An input component for entering a string.
 */
export const Input = styled<React.FunctionComponent<IInputProps>>(props => {
  const {
    // Thread custom props that should not be passed to ant component
    innerRef,
    width,
    // Ant props
    ...rest
  }: IInputProps = props;
  const handleKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp(props.mask ?? ".*");
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
    return true;
  };
  return (
    <AntInput {...rest} ref={props.innerRef} onKeyPress={handleKeyPress} />
  );
})`
  && {
    ${props => (props.width ? "width: " + props.width + ";" : "")}
  }
`;
