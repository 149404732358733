import { Modal as AntModal } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IModalProps {
  /**
   * Function that will be called when modal is closed completely.
   */
  afterClose?: () => void;
  /**
   * Text of the Cancel button
   */
  cancelText?: string;
  /**
   * Centered Modal
   */
  centered?: boolean;
  /**
   * Whether a close button is visible on top right of the modal dialog or not
   */
  closable?: boolean;
  /**
   * Footer content, set as footer={null} when you don't need default buttons
   */
  footer?: React.ReactNode;
  /**
   * Whether to close the modal dialog when the mask (area outside the modal) is clicked
   */
  maskClosable?: boolean;
  /**
   * Text of the OK button
   */
  okText?: string;
  /**
   * Function that will be called when a user clicks mask, close button on top right or Cancel button
   */
  onCancel?: (...args: any[]) => any | PromiseLike<any>;
  /**
   * Function that will be called when a user clicks the OK button
   */
  onOk?: (...args: any[]) => any | PromiseLike<any>;
  /**
   * The modal dialog's title
   */
  title?: React.ReactNode;
  /**
   * Whether the modal dialog is visible or not
   */
  visible?: boolean;
  /**
   * Width of the modal dialog
   */
  width?: string | number;
  /**
   * destroy state after closing
   */
  destroyOnClose?: true;
  /**
   * Whether area outside of modal should be masked
   */
  mask?: boolean;
  /**
   * Props to pass to the Okay button
   */
  okButtonProps?: IOkButtonProps;
}

interface IOkButtonProps {
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  loading?: boolean;
  target?: string;
}

/**
 * A modal dialog.
 */

const StyledModal = styled(AntModal)`
  && {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export class Modal extends React.PureComponent<IModalProps> {
  public static confirm = AntModal.confirm;
  public static warning = AntModal.warning;
  public static error = AntModal.error;
  public static info = AntModal.info;
  public static success = AntModal.success;
  public static warn = AntModal.warn;

  constructor(props: IModalProps) {
    super(props);
  }
  public render() {
    return <StyledModal {...this.props} />;
  }
}
