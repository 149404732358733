import * as React from "react";
import {
  DndProvider,
  DragSource,
  DropTarget,
  DropTargetMonitor,
  DragSourceMonitor
} from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { List, IListProps } from "./List";
import { IListItemProps, ListItem } from "./ListItem";
import styled from "styled-components";

let draggingIndex = -1;

export interface IDragListProps<T> extends IListProps {
  /**
   * Represents the content for a specified record that will be rendered in a DragListItem.
   */
  renderItemChildren: (record: T, index: number) => React.ReactNode;

  /**
   * Sets the handler for the event where a row/item was moved to a new index.
   */
  onMoveRow: (fromIndex: number, toIndex: number) => void;

  /**
   * The data source of the DragList.
   */
  data: T[];
}

interface IDragListItemProps extends IListItemProps {
  /**
   * The index of the item in the list.
   */
  index: number;

  /**
   * Set to true if there is a drag operation in progress, and the pointer is currently hovering over the drop target monitor's owner.
   */
  isOver: boolean;

  /**
   * A function connecting the source DOM node to the React DnD backend.
   */
  connectDragSource: any;

  /**
   * A function connecting the target DOM node to the React DnD backend.
   */
  connectDropTarget: any;

  /**
   * Called when this item is moved to a new index.
   */
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const StyledDragListItemBase = styled<
  React.FunctionComponent<IDragListItemProps>
>((props: IDragListItemProps) => {
  const {
    connectDragSource,
    connectDropTarget,
    isOver,
    moveRow,
    ...rest
  } = props;
  return <ListItem {...rest} />;
})`
  &&& {
    cursor: grab;
    ${(props: IDragListItemProps) =>
      props.isOver && props.index > draggingIndex
        ? "border-bottom: 2px dashed #1890ff;"
        : ""}
    ${(props: IDragListItemProps) =>
      props.isOver && props.index < draggingIndex
        ? "border-top: 2px dashed #1890ff;"
        : ""}
  }
`;

class DragListItemBase extends React.PureComponent<IDragListItemProps> {
  render() {
    const { connectDragSource, connectDropTarget } = this.props;

    return connectDragSource(
      connectDropTarget(
        <div>
          <StyledDragListItemBase {...this.props} />
        </div>
      )
    );
  }
}

const rowSource = {
  beginDrag(props: any) {
    draggingIndex = props.index;
    return {
      index: props.index
    };
  }
};

const rowTarget = {
  drop(
    props: any,
    monitor: DropTargetMonitor<any, any> | DragSourceMonitor<any, any>
  ) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const DragListItem = DropTarget(
  "row",
  rowTarget,
  (connect: any, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  })
)(
  DragSource("row", rowSource, connect => ({
    connectDragSource: connect.dragSource()
  }))(DragListItemBase)
);

export const DragList = <T extends object>(
  props: IDragListProps<T> & { children?: React.ReactNode }
) => {
  const { renderItemChildren, onMoveRow, data, ...rest } = props;
  return (
    <DndProvider backend={HTML5Backend}>
      <List {...rest}>
        {props.data.map((record, index) => (
          <DragListItem index={index} key={index} moveRow={props.onMoveRow}>
            {props.renderItemChildren(record, index)}
          </DragListItem>
        ))}
      </List>
    </DndProvider>
  );
};
