import React from "react";
import { PrintPage } from "./PrintPage";
import { Col } from "./Col";
import { Row } from "./Row";

export interface IPrintColumnLayoutProps {
  /**
   * The number of columns
   */
  columnCount: number;
  /**
   * Page header content
   */
  header?: React.ReactNode;

  /**
   * One or more PrintBlock components
   */
  children?: React.ReactNode;
}

/**
 * A multi-column layout container popular for print media
 */
export const PrintColumnLayout = (props: IPrintColumnLayoutProps) => {
  let columnTotal: number = 0;
  let pages: React.ReactNode[][][] = [];
  let columns: React.ReactNode[][] = [];
  let column: React.ReactNode[] = [];
  columns.push(column);
  pages.push(columns);
  let blocks: React.ReactNode[] = [];
  getPrintBlocks(blocks, props.children);
  let breakBefore = false;
  blocks.forEach((value: React.ReactElement) => {
    if (breakBefore === true) {
      columnTotal++;
      if (columnTotal == props.columnCount) {
        columns = [];
        pages.push(columns);
        columnTotal = 0;
      }
      column = [];
      columns.push(column);
    }
    column.push(value);
    breakBefore = value?.props?.breakAfter;
  });
  return (
    <>
      {pages.map((columns, index) => {
        return (
          <PrintPage key={index}>
            <Row type="flex" grow={0}>
              {props.header}
            </Row>
            <Row type="flex" grow={1} margin="0.25in 0 0 0">
              {columns.map((column, index) => {
                return (
                  <Col
                    key={index}
                    span={24 / props.columnCount}
                    padding="0 15px"
                  >
                    {column}
                  </Col>
                );
              })}
            </Row>
          </PrintPage>
        );
      })}
    </>
  );
};

function getPrintBlocks(
  blocks: Array<React.ReactNode>,
  children: React.ReactNode
) {
  React.Children.forEach(children, (element: React.ReactElement) => {
    if (element?.props?.breakAfter !== undefined) {
      blocks.push(element);
    } else {
      getPrintBlocks(blocks, element.props.children);
    }
  });
}
