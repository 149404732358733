import { Tabs as AntTabs } from "antd";
import * as React from "react";

export interface ITabsProps {
  /**
   * Current TabPane's key
   */
  activeKey?: string;
  /**
   * Whether to change tabs with animation. Only works while tabPosition="top"\|"bottom"
   */
  animated?: boolean | { inkBar: boolean; tabPane: boolean };
  /**
   * Initial active TabPane's key, if activeKey is not set.
   */
  defaultActiveKey?: string;
  /**
   * Hide plus icon or not. Only works while type="editable-card"
   */
  hideAdd?: boolean;
  /**
   * Callback executed when active tab is changed
   */
  onChange?: (activeKey: string) => void;
  /**
   * Callback executed when tab is added or removed. Only works while type="editable-card"
   */
  onEdit?: (
    targetKey: string | React.MouseEvent<HTMLElement>,
    action: any
  ) => void;
  /**
   * Callback executed when next button is clicked
   */
  onNextClick?: React.MouseEventHandler<any>;
  /**
   * Callback executed when prev button is clicked
   */
  onPrevClick?: React.MouseEventHandler<any>;
  /**
   * Callback executed when tab is clicked
   */
  onTabClick?: () => void;
  /**
   * Replace the TabBar
   */
  renderTabBar?: (
    props: ITabsProps,
    DefaultTabBar: React.ReactNode
  ) => React.ReactElement<any>;
  /**
   * Preset tab bar size
   */
  size?: "small" | "middle" | "large";
  /**
   * Extra content in tab bar
   */
  tabBarExtraContent?: React.ReactNode | null;
  /**
   * The gap between tabs
   */
  tabBarGutter?: number;
  /**
   * Position of tabs
   */
  tabPosition?: "top" | "right" | "bottom" | "left";
  /**
   * Basic style of tabs
   */
  type?: "line" | "card" | "editable-card" | "drawer";
}

/**
 * A set of tabs.
 */
export class Tabs extends React.PureComponent<ITabsProps> {
  public render() {
    const { type, ...rest }: ITabsProps = this.props;
    if (type === "drawer") {
      return <AntTabs className="ant-tabs-drawer" {...rest} />;
    }
    return <AntTabs type={type} {...rest} />;
  }
}
