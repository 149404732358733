import * as React from "react";
import styled from "styled-components";
import { Col } from "../Layout/Col";
import { Grid } from "../Layout/Grid";
import { Row } from "../Layout/Row";
import { TabPane } from "../Navigation/TabPane";
import { Tabs } from "../Navigation/Tabs";
import { Input } from "../UserInput/Input";
import { Button } from "./Button";

interface ISignatureProps {
  /**
   * id of the element
   */
  id?: string;
  // ** The callback save function */
  onSave: (signature: string, isImage: boolean, isBlank: boolean) => void;
}

interface ISignatureState {
  tab: string;
  textSign: string;
}

const Canvas = styled.canvas`
  border: 1px dashed grey;
  margin-bottom: 9px;
`;

export class Signature extends React.Component<
  ISignatureProps,
  ISignatureState
> {
  private canvas: HTMLCanvasElement | null;
  private canvasRef: React.RefObject<any>;
  private drawOn: boolean;
  private ctx: any;
  private prevX = 0;
  private currX = 0;
  private prevY = 0;
  private currY = 0;

  constructor(props: ISignatureProps) {
    super(props);
    this.state = {
      tab: "canvas",
      textSign: ""
    };
    this.canvasRef = React.createRef();
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleTouchDown = this.handleTouchDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.finishDrawing = this.finishDrawing.bind(this);
    this.setTab = this.setTab.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTextSignChange = this.handleTextSignChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  public componentDidMount() {
    this.canvas = this.canvasRef.current;

    if (this.canvas == null) {
      throw new Error(
        'Canvas with "sign-canvas" id must be presented in the "Signature" component'
      );
    }
    this.ctx = this.canvasRef.current!.getContext("2d");
    this.handleClear();
  }

  public render() {
    return (
      <Grid>
        <Row>
          <Col margin="12px 12px 8px 12px">
            <Tabs
              defaultActiveKey="canvas"
              onTabClick={this.setTab.bind(TabPane)}
            >
              <TabPane key="canvas" tab="Draw">
                <Row type="flex" justify="center">
                  <Canvas
                    ref={this.canvasRef}
                    id="sign-canvas"
                    width={300}
                    height={90}
                    style={
                      this.state.tab !== "canvas"
                        ? { display: "none" }
                        : { touchAction: "none" }
                    }
                    onMouseMove={this.handleMouseMove}
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.finishDrawing}
                    onMouseOut={this.finishDrawing}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.finishDrawing}
                    onTouchStart={this.handleTouchDown}
                  />
                </Row>
                <Row type="flex">
                  <Col margin="8px 8px 0 0">
                    <Button type="primary" onClick={this.handleSave}>
                      Submit
                    </Button>
                  </Col>
                  <Col margin="8px 0 0 0">
                    <Button onClick={this.handleClear}>Clear</Button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane key="type" tab="Type">
                <Row margin="32px 8px 32px 8px">
                  <Col>
                    <Input
                      placeholder="type"
                      type="text"
                      value={this.state.textSign}
                      onChange={this.handleTextSignChange}
                    />
                  </Col>
                </Row>
                <Row type="flex">
                  <Col margin="8px 8px 0 0">
                    <Button type="primary" onClick={this.handleSave}>
                      Submit
                    </Button>
                  </Col>
                  <Col margin="8px 0 0 0">
                    <Button onClick={this.handleClear}>Clear</Button>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Grid>
    );
  }

  private handleClear() {
    if (this.state.tab === "canvas") {
      this.ctx.fillStyle = "white";
      this.ctx.fillRect(0, 0, 300, 90);
    } else {
      this.setState({ textSign: "" });
    }
  }

  private setTab(key: string) {
    this.setState({ tab: key });
  }

  private handleMouseMove = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (this.drawOn) {
      this.prevX = this.currX;
      this.prevY = this.currY;
      this.currX = e.clientX - this.canvas!.getBoundingClientRect().left;
      this.currY = e.clientY - this.canvas!.getBoundingClientRect().top;

      this.ctx.beginPath();
      this.ctx.moveTo(this.prevX, this.prevY);
      this.ctx.lineTo(this.currX, this.currY);
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
      this.ctx.closePath();
    }
  };

  private handleTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    if (this.drawOn) {
      const touch = e.touches[0];

      this.prevX = this.currX;
      this.prevY = this.currY;
      this.currX = touch.clientX - this.canvas!.getBoundingClientRect().left;
      this.currY = touch.clientY - this.canvas!.getBoundingClientRect().top;

      this.ctx.beginPath();
      this.ctx.moveTo(this.prevX, this.prevY);
      this.ctx.lineTo(this.currX, this.currY);
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
      this.ctx.closePath();
    }
  };

  private handleMouseDown = (e: any) => {
    e.preventDefault();
    this.prevX = this.currX;
    this.prevY = this.currY;
    this.currX = e.clientX - this.canvas!.getBoundingClientRect().left;
    this.currY = e.clientY - this.canvas!.getBoundingClientRect().top;

    this.drawOn = true;
    this.ctx.beginPath();
    this.ctx.fillStyle = "black";
    this.ctx.fillRect(this.currX, this.currY, 2, 2);
    this.ctx.closePath();
  };

  private handleTouchDown = (e: React.TouchEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    const touch = e.touches[0];

    this.prevX = this.currX;
    this.prevY = this.currY;
    this.currX = touch.clientX - this.canvas!.getBoundingClientRect().left;
    this.currY = touch.clientY - this.canvas!.getBoundingClientRect().top;

    this.drawOn = true;
    this.ctx.beginPath();
    this.ctx.fillStyle = "black";
    this.ctx.fillRect(this.currX, this.currY, 2, 2);
    this.ctx.closePath();
  };

  private finishDrawing = (e: any) => {
    e.preventDefault();
    this.drawOn = false;
  };

  private handleSave = () => {
    if (this.state.tab !== "canvas") {
      this.ctx.fillStyle = "white";
      this.ctx.fillRect(0, 0, 300, 90);
      this.ctx.font = "14pt cursive";
      this.ctx.fillStyle = "black";
      this.ctx.fillText(this.state.textSign, 10, 50);
    }
    const image = this.canvasRef.current!.toDataURL().substring(22);
    this.props.onSave(image, true, this.isBlank(this.canvas));
  };

  private handleTextSignChange = (e: any) => {
    this.setState({
      textSign: e.target.value
    });
  };

  private isBlank(canvas: HTMLCanvasElement | null) {
    if (!canvas) {
      return true;
    }
    const context = canvas.getContext('2d');
  
    if (!context) {
      return true;
    }
  
    return !context.getImageData(0, 0, canvas.width, canvas.height).data.some(channel => channel !== 255); 
  }
}
