import { Typography } from "antd";
import * as React from "react";
import { ITypographyProps } from "./TypographyProps";

export interface ITitleProps extends ITypographyProps {
  /**
   * Set content importance. Match with h1, h2, h3, h4
   */
  level?: 1 | 2 | 3 | 4;
  /**
   * Display ellipsis when overflow. Can config rows and expandable by using object
   */
  ellipsis?:
    | boolean
    | { rows: number; expandable: boolean; onExpand: () => void };
}

export class Title extends React.PureComponent<ITitleProps> {
  public render() {
    return (
      <Typography.Title {...this.props}>{this.props.children}</Typography.Title>
    );
  }
}
