import { Avatar as AntAvatar } from "antd";
import * as React from "react";

export interface IAvatarProps {
  /**
   * Defines the alternative text describing the image
   */
  alt?: string | null;
  /**
   * Color
   */
  color?:
    | "primary"
    | "default"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Type of the Icon to be used in avatar
   */
  icon?: string;
  /**
   * Shape of avatar image
   */
  shape?: "circle" | "square";
  /**
   * Size of avatar, options: `large`, `small`, `default`
   * or a custom number size
   */
  size?: "large" | "small" | "default" | number;
  /**
   * Url of image avatar
   */
  src?: string | null;
  /**
   * Avatar content to use instead of an icon or image.
   */
  children?: any;
  /**
   * Callback when avatar is pressed
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Callback when img load error.
   * Return false to prevent Avatar show default fallback behavior, then you can do fallback by your self
   */
  onError?: () => boolean;
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

/**
 * An avatar graphic
 */
export class Avatar extends React.PureComponent<IAvatarProps> {
  public render() {
    const newProps: any = { ...this.props };
    delete newProps.color;
    delete newProps.variation;
    if (newProps.alt == null) {
      delete newProps.alt;
    }
    if (newProps.src == null) {
      delete newProps.src;
    }
    newProps.className = this.props.color
      ? this.props["className"] +
        " ant-bg-" +
        this.props.color +
        (this.props.variation ? "-" + this.props.variation : "")
      : this.props["className"];
    return <AntAvatar {...newProps} />;
  }
}
