import { blue300, grey300, grey400 } from "../../../../Constants";

export const ScreenChartStyle = `
@media screen {
  svg:not(:root) {
    overflow: visible;
  }
  /* Axis labels */
  .ct-label.ct-horizontal.ct-end {
    font-size: 15px;
    color: rgba(0,0,0,0.7);
    justify-content: center;
    margin-top: 10px;
    transform: translateX(-50%);
  }
  /* Data points */
  .ct-standard {
    fill: ${blue300};
    stroke: white;
    stroke-width: 4px;
  }

  /* Line color */
  .ct-line {
    stroke: ${blue300};
  }
  
  .ct-standard.ct-dim {
    fill: ${grey300};
  }

  .ct-border-rect {
    stroke-dasharray: 1, 16, 27;
  }
  
  .ct-border {
    stroke: ${blue300};
    fill: white;
    stroke-width: 1px;
  }
  
  .ct-dim .ct-border {
    stroke: ${grey300};
  }
  
  /* Standard data point */
  .ct-standard.ct-outlined {
    fill: none;
    stroke: none;
  }
  
  /* Standard data point with note */
  .ct-in-circle {
    fill: ${blue300};
  }
  
  .ct-dim .ct-in-circle {
    fill: ${grey300};
  }
    
  .ct-triangle-left,
  .ct-triangle-right {
    stroke: ${blue300};
  }
  
  .ct-dim .ct-triangle-left,
  .ct-dim .ct-triangle-right {
    stroke: ${grey300};
  }
  
  /* Pre-test, post-test half outline */
  .ct-pretest .ct-triangle-right,
  .ct-posttest .ct-triangle-left {
    stroke-dasharray: 1, 10, 21;
    fill: white;
  }
  
  .ct-pretest.ct-outlined .ct-triangle-right,
  .ct-posttest.ct-outlined .ct-triangle-left {
    stroke-dasharray: 1, 8, 16;
  }
  
  .ct-pretest .ct-triangle-left,
  .ct-posttest .ct-triangle-right,
  .ct-probe .ct-triangle-right,
  .ct-probe .ct-triangle-left {
    fill: ${blue300};
  }

  .ct-pretest.ct-dim .ct-triangle-left,
  .ct-posttest.ct-dim .ct-triangle-right,
  .ct-probe.ct-dim .ct-triangle-right,
  .ct-probe.ct-dim .ct-triangle-left {
    fill: ${grey300};
  }
  
  /* Pre-test, post-test data points with note */
  .ct-out-rect {
    fill: white;
  }
  
  .ct-line.ct-dim {
    stroke: ${grey300};
  }

  .ct-grid {
    stroke-dasharray: 0;
  }

  .ct-grid.ct-vertical {
    stroke: ${grey400};
  }

  .ct-out-circle {
    stroke: white;
    stroke-width: 7px;
  }

  .ct-empty {
    display: none;
  }
  
  .ct-grid.ct-separator {
    stroke: rgba(0, 0, 0, 0.7);
    stroke-dasharray: 5px;
  }
  
  .ct-dot {
    cursor: pointer;
  }
}
`;
