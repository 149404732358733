import { Form as AntForm, FormInstance } from "antd";
import * as React from "react";
import { IColProps } from "../Layout/Col";
import styled from "styled-components";
import { ReactElement } from "react";

interface IFormItemRule {
  enum?: any[];
  len?: number;
  max?: number;
  message?: string | ReactElement;
  min?: number;
  pattern?: RegExp;
  required?: boolean;
  transform?: (value: any) => any;
  type?:
    | "string"
    | "number"
    | "boolean"
    | "method"
    | "regexp"
    | "integer"
    | "float"
    | "object"
    | "enum"
    | "date"
    | "url"
    | "hex"
    | "email";
  whitespace?: boolean;
  /** Customize rule level `validateTrigger`. Must be subset of Field `validateTrigger` */
  validateTrigger?: string | string[];
  validator?: (
    rule: IFormItemRule,
    value: any,
    callback: (error?: string) => void
  ) => Promise<void | any> | void;
}

interface IAntFormItemProps {
  /**
   * Container contents
   */
  children?: React.ReactNode;
  /**
   * Used with label, whether to display : after label text.
   */
  colon?: boolean;
  /**
   * Any fields that this item is dependent on
   */
  dependencies?: string[];
  /**
   * The extra prompt message. It is similar to help. Usage example: to display error message and prompt message at the same time.
   */
  extra?: React.ReactNode;
  /**
   * Used with validateStatus, this option specifies the validation status icon. Recommended to be used only with Input.
   */
  hasFeedback?: boolean;
  /**
   * The prompt message. If not provided, the prompt message will be generated by the validation rule.
   */
  help?: React.ReactNode;
  /**
   * Initial field value
   */
  initialValue?: any;
  /**
   * Label text
   */
  label?: React.ReactNode;
  /**
   * The layout of label. You can set span offset to something like {span: 3, offset: 12} or sm: {span: 3, offset: 12} same as with <Col>
   */
  labelCol?: IColProps;
  /**
   * Name of field
   */
  name?: string;
  /**
   * Whether provided or not, it will be generated by the validation rule.
   */
  required?: boolean;
  /**
   * The validation status. If not provided, it will be generated by validation rule.
   */
  validateStatus?: "success" | "warning" | "error" | "validating";
  /**
   * The layout for input controls, same as labelCol
   */
  wrapperCol?: IColProps;
  /**
   * Validation rules
   */
  rules?: (IFormItemRule | ((form: FormInstance) => IFormItemRule))[];
}

interface INewFormItemProps {
  /**
   * Line height of form item contents
   */
  lineHeight?: string;
}

export type IFormItemProps = IAntFormItemProps & INewFormItemProps;

const StyledFormItem = styled<React.FunctionComponent<IFormItemProps>>(
  props => {
    const {
      // Thread custom props that should not be passed to ant component
      lineHeight,
      // Ant props
      ...rest
    }: IFormItemProps = props;

    return <AntForm.Item {...rest} />;
  }
)`
  && {
    .ant-form-item-control-wrapper .ant-form-item-control {
      ${props =>
        props.lineHeight ? "line-height: " + props.lineHeight + ";" : ""}
    }
  }
`;

/**
 * Data entry form.
 */
export class FormItem extends React.PureComponent<IFormItemProps> {
  public render() {
    return <StyledFormItem {...this.props} />;
  }
}
