import { AutoComplete as AntAutoComplete } from "antd";
import * as React from "react";
import { ILabeledValue } from "../Core/ILabeledValue";
import { ISelectOptionProps } from "./SelectOption";
import { ISelectOptionGroupProps } from "./SelectOptionGroup";

export interface IDataSourceItemObject {
  value: string;
  text: string;
}

export interface IAutoCompleteInputProps {
  onChange?: React.FormEventHandler<any>;
  value: any;
}

export interface IAutoCompleteProps {
  /**
   * Show clear button, effective in multiple mode only.
   */
  allowClear?: boolean;
  /**
   * Get focus when component mounted
   */
  autoFocus?: boolean;
  /**
   * Backfill selected item the input when using keyboard
   */
  backfill?: boolean;
  /**
   *
   */
  children?:
    | HTMLInputElement
    | HTMLTextAreaElement
    | React.ReactElement<IAutoCompleteInputProps>
    | React.ReactElement<ISelectOptionProps>
    | Array<React.ReactElement<ISelectOptionProps>>;
  /**
   * Whether active first option by default
   */
  defaultActiveFirstOption?: boolean;
  /**
   * Initial open state of dropdown
   */
  defaultOpen?: boolean;
  /**
   * Initial selected option.
   */
  defaultValue?: string;
  /**
   * Data source for autocomplete
   */
  dataSource?: Array<
    | string
    | IDataSourceItemObject
    | React.ReactElement<ISelectOptionProps>
    | React.ReactElement<ISelectOptionGroupProps>
  >;
  /**
   * Whether disabled select
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * I f true, filter options by input, if function, filter options against it.
   * if the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded.
   */
  filterOption?: boolean;
  /**
   * Controls open state of dropdown
   */
  open?: boolean;
  /**
   * Which prop value of option will render as content of select.
   */
  optionLabelProp?: string;
  /**
   * Placeholder to display inside input box
   */
  placeholder: string;
  /**
   * Called when leaving the component
   */
  onBlur?: React.FocusEventHandler<HTMLElement>;
  /**
   * Called when select an option or input value change, or value of input is changed
   */
  onChange?: (
    value:
      | string
      | string[]
      | number
      | number[]
      | ILabeledValue
      | ILabeledValue[]
  ) => void;
  /**
   * Called when dropdown open
   */
  onDropdownVisibleChange?: (open: boolean) => void;
  /**
   * Called when entering the component
   */
  onFocus?: () => void;
  /**
   * Called when searching items.
   */
  onSearch?: (value: string) => any;
  /**
   * Called when a option is selected. param is option's value and option instance.
   */
  onSelect?: (
    value:
      | string
      | string[]
      | number
      | number[]
      | ILabeledValue
      | ILabeledValue[],
    option: object
  ) => any;
  /**
   * Selected option
   */
  value?: string;
}

/**
 * An input component with autocomplete functionality
 */
export class AutoComplete extends React.PureComponent<IAutoCompleteProps> {
  public render() {
    return <AntAutoComplete {...this.props} />;
  }
}
