import { Progress as AntProgress } from "antd";
import * as React from "react";

export interface IProgressProps {
  /**
   * the gap degree of half circle, 0 ~ 360
   */
  gapDegree?: number;
  /**
   * the gap position, options: top bottom left right
   */
  gapPosition?: "top" | "bottom" | "left" | "right";
  /**
   * to set the completion percentage
   */
  percent?: number;
  /**
   * whether to display the progress value and the status icon
   */
  showInfo?: boolean;
  /**
   * to set the status of the Progress, options: success exception active
   */
  status?: "success" | "active" | "exception";
  /**
   * color of progress bar
   */
  strokeColor?: string;
  /**
   * to set the style of the progress linecap
   */
  strokeLinecap?: "square" | "butt" | "round";
  /**
   * to set the width of the circular progress bar, unit: percentage of the canvas width
   */
  strokeWidth?: number;
  /**
   * segmented success percent, works when type="line"
   */
  successPercent?: number;
  /**
   * to set the type, options: line circle dashboard
   */
  type?: "line" | "circle" | "dashboard";
  /**
   * to set the canvas width of the circular progress bar, unit: px
   */
  width?: number;
  /**
   * callback for formatting info to display
   */
  format?: (percent: number, successPercent: number) => string;
}

/**
 * Progress component.
 */
export class Progress extends React.PureComponent<IProgressProps> {
  public render() {
    return <AntProgress {...this.props} />;
  }
}
