import { Select } from "./Select";
import { SelectOption } from "./SelectOption";
import * as React from "react";

import countryData from "./iso-3166-2.json";

export const CountryData = countryData;

export interface ICountryPickerProps {
  /**
   * Show clear button, effective in multiple mode only.
   */
  allowClear?: boolean;
  /**
   * Whether the current search will be cleared on selecting an item. Only applies when mode is set to multiple or tags
   */
  autoClearSearchValue?: boolean;
  /**
   * Get focus when component mounted
   */
  autoFocus?: boolean;
  /**
   * Whether active first option by default
   */
  defaultActiveFirstOption?: boolean;
  /**
   * Initial open state of dropdown
   */
  defaultOpen?: boolean;
  /**
   * Initial selected option.
   */
  defaultValue?: string | number;
  /**
   * Whether disabled select
   */
  disabled?: boolean;
  /**
   * Whether dropdown's width is same with select.
   */
  dropdownMatchSelectWidth?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Value of action option by default
   */
  filterActiveValue?: string | string[];
  /**
   * Whether to embed label in value, turn the format of value from string to {key: string, label: ReactNode}
   */
  labelInValue?: boolean;
  /**
   * Controls open state of dropdown
   */
  open?: boolean;
  /**
   * Placeholder to display inside input box
   */
  placeholder?: string;
  /**
   * Called when leaving the component
   */
  onBlur?: React.FocusEventHandler<HTMLElement>;
  /**
   * Called when select an option or input value change, or value of input is changed
   */
  onChange?: (value: string | number) => void;
  /**
   * Called when a option is deselected, the params are option's value (or key).
   * Only called for multiple or tags, effective in multiple or tags mode only.
   */
  onDeselect?: () => void;
  /**
   * Called when dropdown open
   */
  onDropdownVisibleChange?: (open: boolean) => void;
  /**
   * Called when entering the component
   */
  onFocus?: () => void;
  /**
   * Called when key pressed
   */
  onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Called when mouse enters select
   */
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Called when mouse leaves select
   */
  onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Called when select scrolls
   */
  onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
  /**
   * Called when a option is selected. param is option's value and option instance.
   */
  onSelect?: (value: string | number, option: object) => any;
  /**
   * Whether to show the drop-down arrow
   */
  showArrow?: boolean;
  /**
   * Size of Select input.
   */
  size?: "large" | "small";
  /**
   * The custom suffix icon
   */
  suffixIcon?: React.ReactNode;
  /**
   * Selected option
   */
  value?: string | number;
  /**
   * Width of select
   */
  width?: string;
}

/**
 * Input control for picking a country.
 */
export const CountryPicker: React.FC<ICountryPickerProps> = props => {
  return (
    <Select
      {...props}
      showSearch={true}
      filterOption={(input, option) => {
        const value = option.value.toString().toLowerCase();
        return value && value.indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {Object.keys(countryData).map(key => {
        return <SelectOption value={key}>{countryData[key].name}</SelectOption>;
      })}
    </Select>
  );
};
