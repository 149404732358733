import { Drawer as AntDrawer } from "antd";
import * as React from "react";
import styled from "styled-components";
import { checkSizeProps, screenSizeFinder } from "../../screenSizeHandler";
import { Grid } from "../Layout/Grid";

export interface IDrawerProps {
  /**
   * Whether a close (x) button is visible on top right of the Drawer dialog or not.
   */
  closable?: boolean;
  /**
   * Whether to unmount child components on closing drawer or not.
   */
  destroyOnClose?: boolean;
  /**
   * Return the mounted node for Drawer.
   */
  getContainer?: () => HTMLElement;
  /**
   * Defines space around outside of drawer using CSS margin syntax
   */
  margin?: string;
  /**
   * Whether to show mask or not.
   */
  mask?: boolean;
  /**
   * Clicking on the mask (area outside the Drawer) to close the Drawer or not.
   */
  maskClosable?: boolean;
  /**
   * Defines space around the drawer's content using CSS padding syntax
   */
  padding?: string;
  /**
   * The title for Drawer.
   */
  title?: string | React.ReactNode;
  /**
   * Whether the Drawer dialog is visible or not.
   */
  visible?: boolean;
  /**
   * Width of the Drawer dialog.
   */
  width?: string | number;
  /**
   * placement is top or bottom, height of the Drawer dialog.
   */
  height?: string | number;
  /**
   * The z-index of the Drawer.
   */
  zIndex?: number;
  /**
   * The placement of the Drawer.
   */
  placement?: "top" | "right" | "bottom" | "left";
  /**
   * Specify a callback that will be called when a user clicks mask, close button or Cancel button.
   */
  onClose?: () => void;
  /**
   * ≥576px, could be a span value
   */
  sm?: boolean;
  /**
   * ≥768px, could be a span value
   */
  md?: boolean;
  /**
   * ≥992px, could be a span value
   */
  lg?: boolean;
  /**
   * ≥1200px, could be a span value
   */
  xl?: boolean;
  /**
   * <576px could be a span value
   */
  xs?: boolean;
  /**
   * ≥1600px could be a span value
   */
  xxl?: boolean;
}

interface IDrawerState {
  width?: number;
}

const StyledDrawer = styled<React.FunctionComponent<IDrawerProps>>(
  (props: IDrawerProps) => {
    const newProps: any = { ...props };
    delete newProps.padding;
    delete newProps.margin;
    delete newProps.grow;
    delete newProps.xs;
    delete newProps.sm;
    delete newProps.md;
    delete newProps.lg;
    delete newProps.xl;
    delete newProps.xxl;
    return <AntDrawer {...newProps} />;
  }
)`
  && {
    & .ant-drawer-body {
      ${props => (props.padding ? "padding: " + props.padding + ";" : "")}
      ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
    }
  }
`;

/**
 * Drawer component.
 */
export class Drawer extends React.PureComponent<IDrawerProps, IDrawerState> {
  constructor(props: IDrawerProps) {
    super(props);
    this.state = {};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  public componentDidMount() {
    if (checkSizeProps(this.props)) {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }
  }

  public componentWillUnmount() {
    if (checkSizeProps(this.props)) {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }
  }

  public render() {
    const displayState =
      this.state.width !== undefined
        ? this.props[screenSizeFinder(this.state.width)]
        : false;
    return displayState === true ? (
      <Grid children={this.props.children} />
    ) : (
      <StyledDrawer {...this.props} />
    );
  }
  private updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
}
