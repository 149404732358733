import * as React from "react";
import styled from "styled-components";
import { grey400 } from "../../../Constants";

interface IYAxisBarProps {
  labels: string[];
}

export const XAxisTitle = styled.div`
  max-width: calc(100% - 130px);
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`;

export const XAxisTitlePrint = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const YAxis = styled.div`
  max-width: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  vertical-align: bottom;
  padding: 35px 0 38px 11px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;

  div {
    flex-grow: 1;
  }

  border-left-style: solid;
  border-color: ${grey400};
  border-width: 1px;
`;

export const YAxisTitle = styled.span`
  writing-mode: vertical-rl;
  display: inline-block;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  padding: 35px 8px 0 8px;
`;

export const YAxisTitlePrint = styled.span`
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: bold;
  padding: 35px 8px 0 8px;
`;

export const YAxisBar = (props: IYAxisBarProps) => {
  return (
    <YAxis>
      <div>{props.labels[10]}</div>
      <div>{props.labels[9]}</div>
      <div>{props.labels[8]}</div>
      <div>{props.labels[7]}</div>
      <div>{props.labels[6]}</div>
      <div>{props.labels[5]}</div>
      <div>{props.labels[4]}</div>
      <div>{props.labels[3]}</div>
      <div>{props.labels[2]}</div>
      <div>{props.labels[1]}</div>
      <div>{props.labels[0]}</div>
    </YAxis>
  );
};
