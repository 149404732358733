import { Pagination as AntPagination } from "antd";
import * as React from "react";

export interface IPaginationProps {
  /**
   * current page number
   */
  current?: number;
  /**
   * Default initial page number
   */
  defaultCurrent?: number;
  /**
   * Default number of data items per page
   */
  defaultPageSize?: number;
  /**
   * Whether to hide pager on single page
   */
  hideOnSinglePage?: boolean;
  /**
   * To customize item innerHTML
   */
  itemRender?: (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => React.ReactNode;
  /**
   * Callback function, executed when the page number is changed, and it takes the resulting page number and pageSize as its arguments
   */
  onChange?: (page: number, pageSize?: number) => void;
  /**
   * Callback function, executed when pageSize is changed
   */
  onShowSizeChange?: (current: number, size: number) => void;
  /**
   * Number of data items per page
   */
  pageSize?: number;
  /**
   * Specify the sizeChanger options
   */
  pageSizeOptions?: string[];
  /**
   * Determine whether you can jump to pages directly
   */
  showQuickJumper?: boolean;
  /**
   * Determine whether pageSize can be changed
   */
  showSizeChanger?: boolean;
  /**
   * To display the total number and range
   */
  showTotal?: (total: number, range: [number, number]) => React.ReactNode;
  /**
   * Whether to use simple mode
   */
  simple?: boolean;
  /**
   * Specify the size of Pagination, can be set to small
   */
  size?: "default" | "small";
  /**
   * Total number of data items
   */
  total?: number;
}

/**
 * Handles navigation of a long list of items using paging.
 */
export class Pagination extends React.PureComponent<IPaginationProps> {
  public render() {
    return <AntPagination {...this.props} />;
  }
}
