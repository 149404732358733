import * as React from "react";
import styled from "styled-components";

export interface IGridProps {
  /**
   * Columns
   */
  children?: React.ReactNode;
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Color
   */
  color?:
    | "primary"
    | "default"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Defines the height of the grid
   */
  height?: string;
  /**
   * Defines space around outside of column using CSS margin syntax
   */
  margin?: string;
  /**
   * Funtion to be fired when grid clicked.
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Opacity
   */
  opacity?: number;
  /**
   * Defines space around the column's content using CSS padding syntax
   */
  padding?: string;
  /**
   * Layout mode
   */
  type?: "flex" | "responsive";
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Defines the width of the grid
   */
  width?: string;
  /**
   * Specifies what happens when content overflows the element box vertically
   */
  overflowY?: "auto" | "scroll" | "hidden" | "visible";
  /**
   * Controls visibility of overlapping elements
   */
  zIndex?: number;
}

interface IGridState {
  windowHeight: number;
}

const StyledGrid = styled<React.FunctionComponent<IGridProps & IGridState>>(
  props => {
    const newProps: any = { ...props };
    delete newProps.opacity;
    delete newProps.height;
    delete newProps.padding;
    delete newProps.color;
    delete newProps.variation;
    delete newProps.width;
    delete newProps.margin;
    delete newProps.type;
    delete newProps.windowHeight;
    delete newProps.overflowY;
    delete newProps.zIndex;
    return <div {...newProps} />;
  }
)`
  && {
    ${props => (props.padding ? "padding: " + props.padding + ";" : "")}
  ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
      ${props =>
        props.type === "flex" ? "display: flex;  flex-direction: column;" : ""}
      ${props =>
        props.height
          ? "height: " +
            (props.height === "100vh"
              ? props.windowHeight + "px"
              : props.height) +
            ";"
          : ""}
      ${props => (props.width ? "width: " + props.width + ";" : "")}
      ${props => (props.opacity ? "opacity: " + props.opacity + ";" : "")}
      ${props => (props.zIndex !== undefined ? "z-index: " + props.zIndex + ";" : "")}
      ${props => (props.overflowY !== undefined ? "overflow-y: " + props.overflowY + ";" : "")}
  }
  `;

/**
 * Container component for grid layouts.
 */
export class Grid extends React.PureComponent<IGridProps, IGridState> {
  constructor(props: IGridProps) {
    super(props);
    this.state = { windowHeight: window.innerHeight };
    this.updateHeight = this.updateHeight.bind(this);
  }
  public componentDidMount() {
    if (this.props.height === "100vh") {
      this.updateHeight();
      window.addEventListener("resize", this.updateHeight);
    }
  }
  public componentWillUnmount() {
    if (this.props.height === "100vh") {
      window.removeEventListener("resize", this.updateHeight);
    }
  }
  public render() {
    if (this.props.color) {
      const className =
        "ant-bg-" +
        this.props.color +
        (this.props.variation ? "-" + this.props.variation : "");
      return (
        <StyledGrid
          className={className}
          {...this.props}
          windowHeight={this.state.windowHeight}
        />
      );
    } else {
      return (
        <StyledGrid {...this.props} windowHeight={this.state.windowHeight} />
      );
    }
  }
  private updateHeight() {
    this.setState({ windowHeight: window.innerHeight });
  }
}
