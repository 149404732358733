import * as React from "react";
import ResponsiveMedia from "react-responsive";

export interface IMediaProps {
  /**
   * The ratio between the width and the height of the viewport
   */
  aspectRatio?: string;
  /**
   * Content of media query
   */
  children?: React.ReactNode;
  /**
   * The viewport height
   */
  height?: string | number;
  /**
   * The maximum ratio between the width and the height of the viewport
   */
  maxAspectRatio?: string;
  /**
   * The maximum viewport height
   */
  maxHeight?: string | number;
  /**
   * The maximum resolution of the output device, using dpi or dpcm
   */
  maxResolution?: string | number;
  /**
   * The maximum viewport width
   */
  maxWidth?: string | number;
  /**
   * The minimum ratio between the width and the height of the viewport
   */
  minAspectRatio?: string;
  /**
   * The minimum viewport height
   */
  minHeight?: string | number;
  /**
   * The minimum resolution of the output device, using dpi or dpcm
   */
  minResolution?: string | number;
  /**
   * The minimum viewport width
   */
  minWidth?: string | number;
  /**
   * The orientation of the viewport (landscape or portrait mode)
   */
  orientation?: "portrait" | "landscape";
  /**
   * Query expression
   */
  query?: string;
  /**
   * The resolution of the output device, using dpi or dpcm
   */
  resolution?: string | number;
  /**
   * The scanning process of the output device
   */
  scan?: "progressive" | "interlace";
  /**
   * The viewport width
   */
  width?: string | number;
  /**
   * Media query type
   */
  type?:
    | "all"
    | "grid"
    | "aural"
    | "braille"
    | "handheld"
    | "print"
    | "projection"
    | "screen"
    | "tty"
    | "tv"
    | "embossed";
}

export interface IBreakPointMediaProps extends IMediaProps {
  /**
   * Include larger screens
   */
  orLarger?: boolean;
  /**
   * Include smaller screens
   */
  orSmaller?: boolean;
}

/**
 * Media query component.
 */
export class Media extends React.PureComponent<IMediaProps> {
  /**
   * Extra small width screens such as mobile phones
   */
  public static Xs = (props: IBreakPointMediaProps) => (
    <Media
      {...Media.filterProps(props)}
      maxWidth={props.orLarger ? undefined : 575}
    />
  );
  /**
   * Small width screens such as mini tablets.
   */
  public static Sm = (props: IBreakPointMediaProps) => (
    <Media
      {...Media.filterProps(props)}
      minWidth={props.orSmaller ? undefined : 576}
      maxWidth={props.orLarger ? undefined : 767}
    />
  );
  /**
   * Medium width screens such as tablets.
   */
  public static Md = (props: IBreakPointMediaProps) => (
    <Media
      {...Media.filterProps(props)}
      minWidth={props.orSmaller ? undefined : 768}
      maxWidth={props.orLarger ? undefined : 991}
    />
  );
  /**
   * Large width screens such as desktop computers.
   */
  public static Lg = (props: IBreakPointMediaProps) => (
    <Media
      {...Media.filterProps(props)}
      minWidth={props.orSmaller ? undefined : 992}
      maxWidth={props.orLarger ? undefined : 1199}
    />
  );
  /**
   * Extra large width screens found on widescreen monitors.
   */
  public static Xl = (props: IBreakPointMediaProps) => (
    <Media
      {...Media.filterProps(props)}
      minWidth={props.orSmaller ? undefined : 1200}
      maxWidth={props.orLarger ? undefined : 1599}
    />
  );
  /**
   * Extra-extra large width screens.
   */
  public static Xxl = (props: IBreakPointMediaProps) => (
    <Media
      {...Media.filterProps(props)}
      minWidth={props.orSmaller ? undefined : 1600}
    />
  );

  public render() {
    return <ResponsiveMedia {...this.props} />;
  }

  private static filterProps = (props: IBreakPointMediaProps) => {
    const {
      // Props that should not be passed to ant component
      orSmaller,
      orLarger,
      minWidth,
      maxWidth,
      // Ant props
      ...rest
    }: IBreakPointMediaProps = props;
    return rest;
  };
}
