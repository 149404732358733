import { Badge as AntBadge } from "antd";
import * as React from "react";

export interface IBadgeProps {
  /**
   * Number to show in badge
   */
  count?: number | string | null;
  /**
   * Whether to show red dot without number
   */
  dot?: boolean;
  /**
   * set offset of the badge dot, like [x, y]
   */
  offset?: [number | string, number | string];
  /**
   * Maximum count to show
   */
  overflowCount?: number;
  /**
   * Whether to show badge when count is zero
   */
  showZero?: boolean;
  /**
   * Set Badge as a status dot
   */
  status?: "success" | "processing" | "default" | "error" | "warning";
  /**
   * If status is set, text sets the display text of the status dot
   */
  text?: string;
  /**
   * Text to show when hovering over the badge
   */
  title?: string;
}

/**
 * A badge
 */
export class Badge extends React.PureComponent<IBadgeProps> {
  public render() {
    return <AntBadge {...this.props} />;
  }
}
