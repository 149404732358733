export enum FrequencyStepsEnum {
  HUNDRED = "hundred",
  TEN = "ten",
  FIVE = "five",
  TWO = "two",
  ONE = "one"
}

export enum DurationStepsEnum {
  HOUR = "hour",
  HALF_AN_HOUR = "halfAnHour",
  TEN_MINUTES = "tenMinutes",
  FIVE_MINUTES = "fiveMinutes",
  MINUTE = "minute",
  HALF_A_MINUTE = "halfAnMinute",
  TEN_SECONDS = "tenSeconds",
  FIVE_SECONDS = "fiveSeconds"
}

export enum PointTypeEnum {
  STANDARD = "standard",
  PRETEST = "pretest",
  PROBE = "probe",
  POSTTEST = "posttest"
}

export type StepBarNameType = FrequencyStepsEnum | DurationStepsEnum;

export interface IChartInfo {
  id: string | null;
  note: boolean;
  type: string | null;
  creatorInitials?: string;
  x?: Date;
  popover?: JSX.Element;
  onPopoverClose?: () => void;
}

export interface IChartData {
  labels: Array<string | null>;
  series: Array<Array<number | null>>;
  info: Array<IChartInfo | null>;
  stepBarName?: StepBarNameType;
  maxValue?: number;
}

export enum DetailTypeEnum {
  ADDITIONAL_INFO = "ADDITIONAL_INFO",
  NONE = "NONE",
  NOTE = "NOTE",
  STO = "STO",
  TARGET = "TARGET",
  TASK = "TASK"
}

export interface IPhaseDetail {
  type: DetailTypeEnum;
  description: string;
  label: string;
}

export interface IChartPhase {
  id: string;
  target: string | null;
  points: IChartInfo[];
  popover: JSX.Element | null;
  pointsNumber: number;
  range: string;
  labelOverride?: string | null;
  additionalInfo?: string | null;
  totalPointsNumber?: number;
  titleLetter?: string;
  details?: IPhaseDetail[];
}
