import { grey300, grey400 } from "../../../../Constants";

export const PrintChartStyle = `
/**
  * Chart Printing styles.
  */
width: 100%;
height: 100%;

.ct-grid {
    stroke-dasharray: 0;
}

.ct-empty {
    display: none;
}

/**
* Chart styles.
*/
.ct-chart-line {
    margin-right: 0;
    overflow: visible;
}

.ct-series-a .ct-line {
    stroke: ${grey400};
    stroke-width: 6px;
}

.ct-grid {
    stroke: ${grey300};
}

.ct-grid.ct-separator {
    stroke: black;
    stroke-width: 2px;
}  

.ct-label.ct-vertical.ct-start,
.ct-label.ct-horizontal.ct-end {
    font-size: 10px;
    font-weight: bold;
    color: black;
}

.ct-label.ct-vertical.ct-start {
    margin-top: 25px;
}

.ct-label.ct-horizontal.ct-end {
    width: 47px !important;
    justify-content: flex-end;
    margin-top: 18px;
    margin-left: -18px;
    transform: rotate(-90deg);
}
`;
