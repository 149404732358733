import Chartist, { ILineChartOptions, IChartistSvg } from "chartist";
import classNames from "classnames";
import * as React from "react";
import ChartistGraph from "@threadlearning/react-chartist";
import styled from "styled-components";
import { IChartData, IChartInfo, IChartPhase } from "./ChartTypes";
import {
  XAxisTitle,
  YAxisBar,
  YAxisTitle,
  YAxisTitlePrint,
  XAxisTitlePrint
} from "./Axes";
import { PhaseBar } from "./PhaseBar";
import { ScreenChartStyle } from "./Styles/ScreenChartStyle";
import { PrintChartStyle } from "./Styles/PrintChartStyle";
import { PrintPointStyle } from "./Styles/PrintPointStyle";
import * as StudentProgressUtils from "./StudentProgressUtils";
import { Row } from "../../Layout/Row";
import { Col } from "../../Layout/Col";
import { Grid } from "../../Layout/Grid";
import { Heading } from "../../Typography/Heading";
import { PhaseBox } from "./PhaseBox";
import { ChartLegend } from "./ChartLegend";
import { Modal } from "../../Messaging/Modal";
import _ from "lodash";
require("array-flat-polyfill");

interface IChartistListenerData {
  axis?: {
    ticks: string[];
  };
  index: number;
  element: {
    addClass: (names: string) => void;
    replace: (element: Chartist.IChartistSvg) => void;
    _node: HTMLElement;
    attr: (attributes: string | object, ns?: string) => string;
  };
  path?: IChartistSVGPath;
  type: string;
}

export interface IChartistGraphProps {
  type: string;
  data: IChartData;
  listener?: {
    draw: (data: IChartistListenerData) => void;
  };
}

export interface IChartProps extends IChartistGraphProps {
  yAxisTitle?: string;
  phases: IChartPhase[];
  yAxisLabels?: string[];
  mode?: "screen" | "print";
  height?: string;
  width?: string;
  showLegend?: boolean;
  scrollToRight?: boolean;
  fetchData?: (id: string) => Promise<void>;
}

interface IChartistSVGPath {
  clone: () => IChartistSVGPath;
  stringify: () => string;
  scale: (x: number, y: number) => IChartistSVGPath;
  translate: (x: number, y: number) => IChartistSVGPath;
  splitByCommand: (command: string) => IChartistSVGPath[];
}

export interface IChartState {
  selectedPhaseId?: string;
  selectedPointIndex?: number;
}

export interface IStyledChartistGraphProps extends IChartistGraphProps {
  //selectedPhaseIndex?: number;
  forceRecreation: boolean;
  yAxisLabels?: string[];
  mode?: "screen" | "print";
}

const StyledChartistGraph = styled<React.FC<IStyledChartistGraphProps>>(
  (props: IStyledChartistGraphProps) => {
    const newProps: IChartistGraphProps = { ...props };
    const options: ILineChartOptions = {
      fullWidth: props.mode === "print",
      axisY: {
        high: props.mode === "print" ? 110 : 100,
        low: 0,
        divisor: props.mode === "print" ? 11 : undefined,
        labelOffset: {
          y: -20
        },
        showGrid: true,
        showLabel: props.mode === "print",
        position: "start",
        type: Chartist.FixedScaleAxis,
        labelInterpolationFnc: labelInterpolation.bind(null, props.yAxisLabels)
      },
      height: props.mode === "print" ? undefined : 400,
      lineSmooth: Chartist.Interpolation.none({
        fillHoles: false
      }),
      chartPadding: {
        top: props.mode === "print" ? 70 : 15,
        right: 15,
        bottom: props.mode === "print" ? 30 : 5,
        left: 10
      },
      width:
        props.mode === "print"
          ? undefined
          : 52 * props.data.series.flat().length + 10 + 40 + 15 + "px" // expected width of each tick, left padding, y axis default offset, right padding
    };
    return <ChartistGraph {...newProps} options={options} />;
  }
)`
  && {
    ${props => (props.mode === "print" ? PrintChartStyle : ScreenChartStyle)}
    ${props => (props.mode === "print" ? PrintPointStyle : "")}
  }
`;

function labelInterpolation(
  labels: string[] | undefined,
  value: string,
  index: number
) {
  if (labels && index < labels.length) {
    return labels[index];
  } else {
    return value;
  }
}

const ChartBlock = styled.div`
  max-width: 100%;
  text-align: center;
  display: flex;
  height: 100%;
`;

const ChartInitials = styled.div`
  position: absolute;
  bottom: 80px;
  right: calc(24px - 1%);
  left: 80px;
  display: flex;
`;

const ChartPhases = styled.div`
  position: absolute;
  top: 80px;
  right: calc(24px - 1%);
  left: 80px;
`;

interface IChartWrapperProps {
  height?: string;
  width?: string;
  children: React.ReactNode;
}

const ChartWrapper = styled.div<IChartWrapperProps>`
  && {
    height: ${props => (props.height ? props.height : "457px")};
    width: ${props => (props.width ? props.width : "auto")};
  }
`;
function getSelectionClasses(
  selectedPhaseId: string | undefined,
  currentPhase: IChartPhase | undefined
) {
  return {
    "ct-dim":
      selectedPhaseId && currentPhase && selectedPhaseId !== currentPhase.id
  };
}

export class Chart extends React.PureComponent<IChartProps, IChartState> {
  private rowRef: React.RefObject<HTMLDivElement>;

  public constructor(props: IChartProps) {
    super(props);
    this.state = {};
    this.rowRef = React.createRef();
    this.handlePhaseClick = this.handlePhaseClick.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  public componentDidMount() {
    this.maybeScrollRight();
  }

  public componentDidUpdate(prevProps: IChartProps) {
    if (this.props.phases !== prevProps.phases) {
      this.setState({
        selectedPhaseId: undefined,
        selectedPointIndex: undefined
      });
    }

    if (this.props.data !== prevProps.data) {
      this.maybeScrollRight();
    }
  }

  public render() {
    const selectedPoint = this.props.phases.map(phase => phase.points).flat()[
      this.state.selectedPointIndex ?? -1
    ];

    return (
      <ChartWrapper height={this.props.height} width={this.props.width}>
        {this.props.mode !== "print" ? (
          <>
            <ChartBlock
              onScroll={() => this.setState({ selectedPointIndex: undefined })}
            >
              <Row
                innerRef={this.rowRef}
                type="flex"
                scroll="horizontal"
                height="100%"
                grow={1}
              >
                <Col>
                  <PhaseBar
                    phases={this.props.phases}
                    onPhaseClick={this.handlePhaseClick}
                  />
                  <Row type="flex" height="calc(100% - 40px)">
                    <Modal
                      centered={true}
                      visible={!!selectedPoint}
                      onCancel={() => this.handlePopoverClose(selectedPoint)}
                      footer={null}
                    >
                      {selectedPoint?.popover}
                    </Modal>
                    <StyledChartistGraph
                      mode={this.props.mode}
                      type={this.props.type}
                      data={this.props.data}
                      forceRecreation={false}
                      listener={{ draw: this.chartDrawHandler.bind(this) }}
                    />
                  </Row>
                </Col>
              </Row>
              <YAxisBar
                labels={
                  this.props.yAxisLabels || [
                    "0%",
                    "10%",
                    "20%",
                    "30%",
                    "40%",
                    "50%",
                    "60%",
                    "70%",
                    "80%",
                    "90%",
                    "100%"
                  ]
                }
              />
              <YAxisTitle>{this.props.yAxisTitle || "Accuracy"}</YAxisTitle>
            </ChartBlock>

            <XAxisTitle>Date</XAxisTitle>
          </>
        ) : (
          <Grid width="100%" height="90%" type="flex" margin="0.25in 0 0 0">
            <ChartPhases>
              <PhaseBox
                phases={this.props.phases}
                maxPoints={this.props.data.labels.length / 2}
              />
            </ChartPhases>
            <ChartBlock>
              <YAxisTitlePrint>
                {this.props.yAxisTitle || "Accuracy"}
              </YAxisTitlePrint>
              <StyledChartistGraph
                {...this.props}
                forceRecreation={true}
                listener={{ draw: this.printDrawHandler.bind(this) }}
              />
            </ChartBlock>
            <ChartInitials>
              {this.props.data.info.map((info, index) => {
                return index % 2 == 0 ? (
                  <div key={index} style={{ flexBasis: 0, flexGrow: 1 }}>
                    <Heading weight="bold" level={7}>
                      {info != null ? info.creatorInitials : ""}
                    </Heading>
                  </div>
                ) : null;
              })}
            </ChartInitials>
            <Row
              type="flex"
              margin="10px 0 0 0"
              justify="space-around"
              alignItems="center"
            >
              <XAxisTitlePrint>Date and Instructor Initials</XAxisTitlePrint>
              {this.props.showLegend && <ChartLegend />}
            </Row>
          </Grid>
        )}
      </ChartWrapper>
    );
  }

  private handlePhaseClick(id: string) {
    this.setState({ selectedPhaseId: id });
  }

  private printDrawHandler(data: IChartistListenerData) {
    if (data.type === "point") {
      var isEvenIndex = data.index % 2 === 0;
      if (!isEvenIndex) {
        data.element.addClass("ct-empty");
      } else {
        var pointShape = StudentProgressUtils.getPointShape(
          this.props.data.info[data.index],
          data,
          "print"
        );
        data.element.replace(pointShape);
      }
    } else if (data.type === "grid") {
      if (data.axis!.ticks[data.index] === "") {
        data.element.addClass("ct-separator");
        const y2 = parseInt(data.element.attr("y2"));
        data.element.attr({ y1: 0, y2: y2 + 60 });
      }
    }
  }

  private chartDrawHandler(data: IChartistListenerData) {
    if (data.type === "point") {
      const isEvenIndex = data.index % 2 === 0;
      const ctNumClass = `ct-num-${data.index}`;
      if (!isEvenIndex) {
        data.element.addClass(`ct-empty ${ctNumClass}`);
      } else {
        let pointClasses: string = classNames({ [ctNumClass]: true }),
          pointShape: IChartistSvg & { _node?: HTMLElement };

        if (
          this.props.data.info[data.index] &&
          this.props.data.info[data.index]!.note
        ) {
          data.element.addClass("ct-note");
        }
        // CRTH-1740: our state resets can lag behind our props by one render, guard against indexes out of range
        if (this.state.selectedPhaseId) {
          const phase = this.getPhaseByChartDataIndex(data.index);
          const isDotSelected = phase?.id === this.state.selectedPhaseId;
          if (!isDotSelected) {
            data.element.addClass("ct-dim");
          }

          pointClasses = classNames(
            { [ctNumClass]: true },
            {
              "ct-dim": !isDotSelected
            }
          );
        }
        pointShape = StudentProgressUtils.getPointShape(
          this.props.data.info[data.index],
          data,
          "web",
          pointClasses
        );
        if (pointShape._node) {
          pointShape._node.onclick = () => {
            this.handlePointClick(pointShape._node!);
          };
        }
        data.element.replace(pointShape);
      }
    } else if (data.type === "grid") {
      if (data.axis!.ticks[data.index] === "") {
        data.element.addClass("ct-separator");
      }
    } else if (data.type === "line") {
      const pathGroup = data.path!.splitByCommand("m");
      const lineGroup = new Chartist.Svg("svg");

      pathGroup.forEach((path: IChartistSVGPath, index: number) => {
        const phase = this.getPhaseByChartDataIndex(index * 2);
        const lineClasses = classNames(
          "ct-line",
          getSelectionClasses(this.state.selectedPhaseId, phase)
        );
        let _ = new Chartist.Svg(
          "path",
          {
            d: path.stringify()
          },
          lineClasses,
          lineGroup
        );
        _ = _;
      });
      data.element.replace(lineGroup);
    }
  }

  private getPhaseByChartDataIndex = (
    index: number
  ): IChartPhase | undefined => {
    let lastIndex = 0;
    for (let i = 0; i < this.props.phases.length; i++) {
      const phase = this.props.phases[i];
      lastIndex += phase.points.length;
      if (index < lastIndex * 2) return phase;
    }
    return undefined;
  };

  private handlePointClick = async (chartPoint: HTMLElement) => {
    const pointIndexStr =
      _(chartPoint.classList)
        .find(className => {
          return className.indexOf("ct-num-") === 0;
        })
        ?.substring(7) || "";
    const pointIndex = parseInt(pointIndexStr, 10) / 2;
    const id =
      this.props.phases.map(phase => phase.points).flat()[pointIndex ?? -1]
        .id || "";
    await this.props.fetchData?.(id);
    this.setState({
      selectedPointIndex: pointIndex
    });
  };

  private handlePopoverClose = (selectedPoint: IChartInfo) => {
    this.setState({ selectedPointIndex: undefined });
    if (selectedPoint && selectedPoint.onPopoverClose) {
      selectedPoint.onPopoverClose();
    }
  };

  private maybeScrollRight() {
    if (this.props.scrollToRight && this.rowRef.current) {
      this.rowRef.current.scrollLeft = this.rowRef.current.scrollWidth;
    }
  }
}
