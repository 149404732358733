import { Switch as AntSwitch } from "antd";
import * as React from "react";

export interface ISwitchProps {
  /**
   * Current state of checkbox.
   * @default false
   */
  checked?: boolean;
  /**
   * Content to be shown when the state is checked
   */
  checkedChildren?: string | React.ReactNode;
  /**
   * Label to display to the right of the checkbox
   */
  children?: React.ReactNode;
  /**
   * Specifies the initial state: whether or not the checkbox is selected.
   */
  defaultChecked?: boolean;
  /**
   * Disable checkbox
   */
  disabled?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Indeterminate checked state of checkbox
   */
  indeterminate?: boolean;
  /**
   * Callback when checkbox is toggled
   */
  onChange?: (value: boolean) => void;
}

/**
 * An input component for controlling a boolean flag.
 */
export class Switch extends React.PureComponent<ISwitchProps> {
  public render() {
    return <AntSwitch {...this.props} />;
  }
}
