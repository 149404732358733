import { Spin as AntSpin } from "antd";
import * as React from "react";

export interface ISpinProps {
  /**
   * specifies a delay in milliseconds for loading state (prevent flush)
   */
  delay?: number;
  /**
   * React node of the spinning indicator
   */
  indicator?: React.ReactElement<any>;
  /**
   * size of Spin, options: small, default and large
   */
  size?: "large" | "small" | "default";
  /**
   * whether Spin is spinning
   */
  spinning?: boolean;
  /**
   * customize description content when Spin has children
   */
  tip?: string;
}
/**
 * Spin component.
 */
export class Spin extends React.PureComponent<ISpinProps> {
  public render() {
    return <AntSpin {...this.props} />;
  }
}
