import { Slider as AntSlider } from "antd";
import * as React from "react";

export interface ISliderMarks {
  [key: number]:
    | React.ReactNode
    | {
        label: React.ReactNode;
      };
}

export interface ISliderProps {
  /**
   * Get focus when component mounted
   */
  autoFocus?: boolean;
  /**
   * The default value of slider. When range is false, use number, otherwise, use [number, number]
   */
  defaultValue?: number;
  /**
   * If true, the slider will not be interactable.
   */
  disabled?: boolean;
  /**
   * Whether the thumb can drag over tick only.
   */
  dots?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * Make effect when marks not null，true means containment and false means coordinative
   */
  included?: boolean;
  /**
   * Tick mark of Slider, type of key must be number, and must in closed interval min, max ，each mark can declare its own style.
   */
  marks?: ISliderMarks;
  /**
   * The maximum value the slider can slide to
   */
  max?: number;
  /**
   * The minimum value the slider can slide to.
   */
  min?: number;
  /**
   * Fire when onmouseup is fired.
   */
  onAfterChange?: (value: number | [number, number]) => void;
  /**
   * Callback function that is fired when the user changes the slider's value.
   */
  onChange?: (value: number | [number, number]) => void;
  /**
   * The granularity the slider can step through values.
   * Must greater than 0, and be divided by (max - min).
   * When marks no null, step can be null.
   */
  step?: number | null;
  /**
   * Slider will pass its value to tipFormatter, and display its value in Tooltip,
   * and hide Tooltip when return value is null.
   */
  tipFormatter?: null | ((value: number) => React.ReactNode);
  /**
   * The value of slider. When range is false, use number, otherwise, use [number, number]
   */
  value?: number;
  /**
   * If true, the slider will be vertical.
   */
  vertical?: boolean;
}

/**
 * An input component for selecting a value within a range.
 */
export class Slider extends React.PureComponent<ISliderProps> {
  public render() {
    return <AntSlider range={false} {...this.props} />;
  }
}
