import { Menu as AntMenu } from "antd";
import * as React from "react";

export interface IMenuItemProps {
  /**
   * Whether menu item is disabled or not
   */
  disabled?: boolean;
  /**
   * id of menu item
   */
  id?: string;
  /**
   * Unique id of the menu item
   */
  key?: string;
  /**
   * Display title for collapsed item
   */
  title?: React.ReactNode;
  /**
   * Contents
   */
  children?: React.ReactNode;
}

/**
 * Menu item component.
 */
export class MenuItem extends React.PureComponent<IMenuItemProps> {
  public render() {
    return <AntMenu.Item {...this.props} />;
  }
}
