import React from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Row } from "./Row";
import { Heading } from "../Typography/Heading";
import { Divider } from "../Other/Divider";

export interface IPrintBlock {
  titleLeft: string;
  titleRight: string;
  caption?: string | null;
  breakAfter: boolean;
  children?: React.ReactNode;
}

const StyledDiv = styled.div`
  page-break-inside: never;
  display: inline-block;
  width: 100%;
  margin-bottom: 50px;
`;

const Caption = styled.div`
  min-height: 40px;
  width: 100%;
  font-size: 20px;
`;

/**
 * A layout component for rendering print output
 */
export const PrintBlock = (props: IPrintBlock) => {
  return (
    <StyledDiv>
      <>
        <Divider height="2px" margin="0 0 10px 0" />
        <Row type="flex" justify="space-between">
          <Col grow={0}>
            <Heading level={4} weight="bold">
              {props.titleLeft}
            </Heading>
          </Col>
          <Col grow={0} margin="3px 0 0 0">
            <Heading level={6} weight="bold">
              {props.titleRight}
            </Heading>
          </Col>
        </Row>
        <Caption>{props.caption}</Caption>
        <Divider height="1px" />
        {props.children}
      </>
    </StyledDiv>
  );
};
