import { List as AntList } from "antd";
import * as React from "react";
import { IListGridType } from "./List";

export interface IListItemProps {
  extra?: React.ReactNode;
  actions?: React.ReactNode[];
  grid?: IListGridType;

  /**
   * Reserved.
   */
  className?: string;
  /**
   * Funtion to be fired when row clicked.
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
}

/**
 * An item within a list.
 */
export class ListItem extends React.PureComponent<IListItemProps> {
  public render() {
    return <AntList.Item {...this.props} />;
  }
}
