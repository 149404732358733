import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IIconProps {
  /**
   * Button label
   */
  children?: React.ReactNode;
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Color
   */
  color?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * id of col
   */
  id?: string;
  /**
   * Callback when icon is pressed
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Defines space around outside of icon using CSS margin syntax
   */
  margin?: string;
  /**
   * Transparency of icon
   */
  opacity?: number;
  /**
   * Size of icon
   */
  size?: string;
  /**
   * Rotate icon with animation
   */
  spin?: boolean;
  /**
   * Theme of the ant design icon
   */
  theme?: "filled" | "outlined" | "twoTone";
  /**
   * Identifies the icon to be rendered.  Fontawesome icon types use the prefix fa- while Material Design
   * icons use the prefix md-
   */
  type: string;
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Width of icon.
   */
  width?: string;
}

const StyledFontAwesomeIcon = styled<React.FunctionComponent<IIconProps>>(
  props => {
    const newProps: any = { ...props };
    delete newProps.size;
    delete newProps.color;
    delete newProps.variation;
    delete newProps.type;
    delete newProps.margin;
    delete newProps.opacity;
    delete newProps.width;
    const parts = props.type.split(" ");
    const prefix = parts.length > 1 ? parts[1] : "fas";
    const icon = parts[0].substring(3);
    return <FontAwesomeIcon icon={[prefix, icon]} {...newProps} />;
  }
)`
  && {
    ${props => (props.size ? "font-size: " + props.size + ";" : "")}
    ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
    ${props => (props.opacity ? "opacity: " + props.opacity + ";" : "")}
    ${props =>
      props.width ? "text-align: center; width: " + props.width + ";" : ""}
    ${props => (props.onClick ? "cursor: pointer;" : "")}
  }
`;

const StyledThreadIcon = styled<React.FunctionComponent<IIconProps>>(props => {
  const {
    size,
    color,
    variation,
    type,
    margin,
    opacity,
    width,
    ...domProps
  } = props;
  return <i {...domProps} />;
})`
  && {
    ${props => (props.size ? "font-size: " + props.size + ";" : "")}
    ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
    ${props => (props.opacity ? "opacity: " + props.opacity + ";" : "")}
    ${props =>
      props.width ? "text-align: center; width: " + props.width + ";" : ""}
    ${props => (props.onClick ? "cursor: pointer;" : "")}
  }
`;

/**
 * A graphic icon
 */
export class Icon extends React.PureComponent<IIconProps> {
  public render() {
    if (this.props.type && this.props.type.startsWith("icon-")) {
      const className =
        this.props.type +
        (this.props.color
          ? " ant-fg-" +
            this.props.color +
            (this.props.variation ? "-" + this.props.variation : "")
          : "");
      return <StyledThreadIcon className={className} {...this.props} />;
    } else if (this.props.type && this.props.type.startsWith("fa-")) {
      const className = this.props.color
        ? " ant-fg-" +
          this.props.color +
          (this.props.variation ? "-" + this.props.variation : "")
        : "";
      return <StyledFontAwesomeIcon className={className} {...this.props} />;
    } else {
      console.warn(
        `Icon of the following type is deprected: ${this.props.type}.`
      );
      return <></>;
    }
  }
}
