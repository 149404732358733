import { Select as AntSelect } from "antd";
import * as React from "react";
import { ILabeledValue } from "../Core/ILabeledValue";
import { ISelectOptionProps } from "./SelectOption";
import styled from "styled-components";

interface IAntSelectProps {
  /**
   * Show clear button, effective in multiple mode only.
   */
  allowClear?: boolean;
  /**
   * Whether the current search will be cleared on selecting an item. Only applies when mode is set to multiple or tags
   */
  autoClearSearchValue?: boolean;
  /**
   * Get focus when component mounted
   */
  autoFocus?: boolean;
  /**
   * Whether active first option by default
   */
  defaultActiveFirstOption?: boolean;
  /**
   * Initial open state of dropdown
   */
  defaultOpen?: boolean;
  /**
   * Initial selected option.
   */
  defaultValue?: string | number;
  /**
   * Whether disabled select
   */
  disabled?: boolean;
  /**
   * Whether dropdown's width is same with select.
   */
  dropdownMatchSelectWidth?: boolean;
  /**
   * id of the element
   */
  id?: string;
  /**
   * I f true, filter options by input, if function, filter options against it.
   * if the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded.
   */
  filterOption?:
    | boolean
    | ((inputValue: string, option: ISelectOptionProps) => any);
  /**
   * Value of action option by default
   */
  filterActiveValue?: string | string[];
  /**
   * Whether to embed label in value, turn the format of value from string to {key: string, label: ReactNode}
   */
  labelInValue?: boolean;
  /**
   * Maximum tag count to show
   */
  maxTagCount?: number;
  /**
   * Placeholder for not showing tags
   */
  maxTagPlaceholder?:
    | React.ReactNode
    | ((
        omittedValues: Array<
          | string
          | string[]
          | number
          | number[]
          | ILabeledValue
          | ILabeledValue[]
        >
      ) => React.ReactNode);
  /**
   * Set mode of Select
   */
  mode?: "multiple" | "tags";
  /**
   * Specify content to show when no result matches.
   */
  notFoundContent?: string;
  /**
   * Controls open state of dropdown
   */
  open?: boolean;
  /**
   * Which prop value of option will be used for filter if filterOption is true
   */
  optionFilterProp?: string;
  /**
   * Which prop value of option will render as content of select.
   */
  optionLabelProp?: string;
  /**
   * Placeholder to display inside input box
   */
  placeholder?: string;
  /**
   * Called when leaving the component
   */
  onBlur?: React.FocusEventHandler<HTMLElement>;
  /**
   * Called when select an option or input value change, or value of input is changed
   */
  onChange?: (value: string | number) => void;
  /**
   * Called when a option is deselected, the params are option's value (or key).
   * Only called for multiple or tags, effective in multiple or tags mode only.
   */
  onDeselect?: () => void;
  /**
   * Called when dropdown open
   */
  onDropdownVisibleChange?: (open: boolean) => void;
  /**
   * Called when entering the component
   */
  onFocus?: () => void;
  /**
   * Called when key pressed
   */
  onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Called when mouse enters select
   */
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Called when mouse leaves select
   */
  onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Called when select scrolls
   */
  onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
  /**
   * Called when searching items.
   */
  onSearch?: (value: string) => any;
  /**
   * Called when a option is selected. param is option's value and option instance.
   */
  onSelect?: (value: string | number, option: object) => any;
  /**
   * Whether to show the drop-down arrow
   */
  showArrow?: boolean;
  /**
   * Whether show search input in single mode.
   */
  showSearch?: boolean;
  /**
   * Size of Select input.
   */
  size?: "large" | "small";
  /**
   * The custom suffix icon
   */
  suffixIcon?: React.ReactNode;
  /**
   * Separator used to tokenize on tag/multiple mode
   */
  tokenSeparators?: string[];
  /**
   * Selected option
   */
  value?: string | number;

  /**
   *	Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}

interface INewSelectProps {
  /**
   * Width of select
   */
  width?: string;
}

export type ISelectProps = IAntSelectProps & INewSelectProps;

const StyledSelect = styled<React.FunctionComponent<ISelectProps>>(props => {
  const getPopupContainer = props.getPopupContainer
    ? props.getPopupContainer
    : (trigger: HTMLElement) => trigger.parentElement as HTMLElement;

  const {
    // Thread custom props that should not be passed to ant component
    width,
    // Ant props
    ...rest
  }: ISelectProps = props;

  return <AntSelect {...rest} getPopupContainer={getPopupContainer} />;
})`
  && {
    ${props => (props.width ? "width: " + props.width + ";" : "")}
  }
`;

/**
 * A select component
 */
export class Select extends React.PureComponent<ISelectProps> {
  public render() {
    return <StyledSelect {...this.props} />;
  }
}
