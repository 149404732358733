import { Input as AntInput } from "antd";
import * as React from "react";

const AntInputTextArea = AntInput.TextArea;

interface IAntInputTextAreaProps {
  /**
   * Whether to autosize box
   */
  autoSize?: boolean | { minRows?: number; maxRows?: number };
  /**
   * value if none passed
   */
  defaultValue?: string;
  /**
   * id of the element
   */
  id?: string;
  /**
   * value
   */
  value?: string;
  /**
   * Function to fire on press enter
   */
  onPressEnter?: (value: any) => void;
  /**
   * Function to fire on change
   */
  onChange?:
    | ((event: React.ChangeEvent<HTMLTextAreaElement>) => void)
    | undefined;
  /**
   * Number of rows on the InputTextArea
   */
  rows?: number;
  /**
   * ref for controlling focus
   */
  innerRef?: React.RefObject<any>;
}

interface INewInputTextAreaProps {}

export type IInputTextAreaProps = IAntInputTextAreaProps &
  INewInputTextAreaProps;

/**
 * An input component for searching a value.
 */
export class InputTextArea extends React.PureComponent<IInputTextAreaProps> {
  public render() {
    const {
      // Thread custom props that should not be passed to ant component
      innerRef,
      // Ant props
      ...rest
    }: IInputTextAreaProps = this.props;

    return <AntInputTextArea {...rest} ref={innerRef} />;
  }
}
