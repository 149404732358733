import { Button } from "./Button";
import { Col } from "../Layout/Col";
import { Grid } from "../Layout/Grid";
import { Row } from "../Layout/Row";
import { Heading } from "../Typography/Heading";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Marquee } from "../Layout/Marquee";
import styled from "styled-components";

export interface IButtonTickerProps {
  /**
   * Number of seconds per button
   */
  interval: number;
  /**
   * Offset in seconds of the first button
   */
  offset: number;
  /**
   * Initial set of buttons
   */
  buttons: IIntervalButton[];
  /**
   * Ticker has stopped
   */
  stopped: boolean;
  /**
   * Called when a new button is required to keep on scrolling
   */
  onNewButton: (
    last: IIntervalButton
  ) => { button: IIntervalButton; offset: number };
  /**
   * Called when a button is pressed
   */
  onButtonPress: (button: IIntervalButton) => React.ReactNode;
}

export interface IIntervalButton {
  label: React.ReactNode;
  seconds: number;
  status?: string;
}

const Container = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
`;

const Scroll = styled.div`
  width: 100%;
  height: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: row-reverse;
  @media (pointer: fine) {
    overflow-x: hidden;
  }
`;

export const ButtonTicker: React.FC<IButtonTickerProps> = props => {
  const [buttons, setButtons] = React.useState<IIntervalButton[]>([]);
  const [offset, setOffset] = React.useState(props.offset);
  const addButton = (newButton: IIntervalButton) => {
    const clone = [...buttons];
    var keep = clone.length > 5 ? clone.slice(1) : clone;
    setButtons([...keep, newButton]);
  };
  React.useEffect(() => {
    let clone = [...props.buttons];
    if (!props.stopped) {
      const spacer: IIntervalButton = { label: "\xa0", seconds: -1 };
      _.times(6 - props.buttons.length, i => {
        clone = [spacer, ...clone];
      });
    }
    setButtons(clone);
  }, [props.buttons, props.stopped]);
  const updateButtonLabel = (index: number, label: React.ReactNode) => {
    const old = buttons[index];
    const updated = { ...old, label };
    const clone = [...buttons];
    clone[index] = updated;
    setButtons(clone);
  };
  const secondsPerHour = 60 * 60;
  const buttonElements = buttons.map((button, index) => {
    const minutes = props.interval % 60 === 0;
    const format = minutes
      ? "H:mm"
      : button.seconds >= secondsPerHour
      ? "H:mm:ss"
      : "mm:ss";
    const time =
      button.seconds === -1
        ? button.label
        : moment("2015-01-01")
            .startOf("day")
            .seconds(button.seconds)
            .format(format);
    const disabled = index === buttons.length - 1 && !props.stopped;
    const color = disabled ? "primary" : "default";
    const variation = disabled ? 6 : 1;
    const timeLevel = format.length > 5 ? 8 : 7;
    const borderless = index !== buttons.length - 2 || props.stopped;
    return (
      <Button
        key={`button${index}`}
        disabled={disabled}
        type="ghost"
        borderless={borderless}
        size="x-large"
        onClick={() => {
          if (!disabled && button.seconds !== -1) {
            var newLabel = props.onButtonPress(button);
            updateButtonLabel(index, newLabel);
          }
        }}
      >
        <Grid width="30px">
          <Row margin="0 auto">
            <Heading level={timeLevel} color={color} variation={variation}>
              {time}
            </Heading>
          </Row>
          <Row margin="0 auto">
            <Heading level={5} color={color} variation={variation}>
              {button.label}
            </Heading>
          </Row>
        </Grid>
      </Button>
    );
  });
  return (
    <>
      <Row type="flex" color="primary" height="64px" margin="0 0 8px 0">
        <Col type="flex" grow={1} color="primary" variation={7}>
          &nbsp;
        </Col>
        {!props.stopped && (
          <Col type="flex" grow={0} color="primary" variation={9}>
            <Grid width="60px">&nbsp;</Grid>
          </Col>
        )}
      </Row>
      <Row type="flex" margin="-72px 0 0 0">
        <Col type="flex" grow={1} span={24}>
          {props.stopped ? (
            <Container>
              <Scroll>{_.reverse(buttonElements)}</Scroll>
            </Container>
          ) : (
            <Marquee
              iteration={
                buttons.length > 1 ? buttons[buttons.length - 2].seconds : 0
              }
              offset={offset}
              duration={props.interval}
              direction="rtl"
              onFinish={() => {
                var result = props.onNewButton(buttons[buttons.length - 1]);
                if (buttons[buttons.length - 1].seconds !== -1) {
                  addButton(result.button);
                  setOffset(result.offset);
                }
              }}
            >
              {buttonElements}
            </Marquee>
          )}
        </Col>
      </Row>
    </>
  );
};
