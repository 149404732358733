import { Steps as AntSteps } from "antd";
import * as React from "react";

export interface IStepsProps {
  /**
   * Type of steps, can be set to one of the following values: default, navigation
   */
  type?: "default" | "navigation";
  /**
   * To set the current step, counting from 0. You can overwrite this state by using status of Step
   */
  current?: number;
  /**
   * To specify the direction of the step bar, horizontal or vertical
   */
  direction?: "horizontal" | "vertical";
  /**
   * Set the initial step, counting from 0
   */
  initial?: number;
  /**
   * Place title and description with horizontal or vertical direction
   */
  labelPlacement?: "horizontal" | "vertical";
  /**
   * Steps with progress dot style, customize the progress dot by setting it to a function. labelPlacement will be vertical
   */
  progressDot?: boolean | Function;
  /**
   * Change to vertical direction when screen width smaller than 532px
   */
  responsive?: boolean;
  /**
   * To specify the size of the step bar, default and small are currently supported
   */
  size?: "default" | "small";
  /**
   * To specify the status of current step, can be set to one of the following values: wait process finish error
   */
  status?: "wait" | "process" | "finish" | "error";
  /**
   * Progress circle percentage of current step in process status (only works on basic Steps)
   */
  percent?: number;
  /**
   * Trigger when Step is changed
   */
  onChange?: (current: number) => void;
}

/**
 * A navigation bar that guides users through the steps of a task.
 */
export class Steps extends React.PureComponent<IStepsProps> {
  public render() {
    return <AntSteps {...this.props} />;
  }
}
