import { TimePicker as AntTimePicker } from "antd";
import moment from "moment";
import * as React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { Icon } from "../Data/Icon";

export interface ITimePickerProps {
  /**
   * Called from timepicker panel to render some addon to its bottom
   */
  addon?: () => React.ReactNode;
  /**
   * Allow clearing text
   */
  allowEmpty?: boolean;
  /**
   * Get focus when component mounted
   */
  autoFocus?: boolean;
  /**
   * Clear tooltip of icon
   */
  clearText?: string;
  /**
   * Default open panel value, used to set utcOffset,locale if value/defaultValue absent
   */
  defaultOpenValue?: moment.Moment;
  /**
   * To set default time
   */
  defaultValue?: moment.Moment;
  /**
   * Determine whether the TimePicker is disabled
   */
  disabled?: boolean;
  /**
   * Specify the hours that cannot be selected
   */
  disabledHours?: () => number[];
  /**
   * Specify the minutes that cannot be selected
   */
  disabledMinutes?: (selectedHour: number) => number[];
  /**
   * Specify the seconds that cannot be selected
   */
  disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
  /**
   * id of the element
   */
  id?: string;
  /**
   * Set the time format
   */
  format?: string;
  /**
   * Hide the options that can not be selected
   */
  hideDisabledOptions?: boolean;
  /**
   * Interval between hours in picker
   */
  hourStep?: number;
  /**
   * Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)
   */
  inputReadOnly?: boolean;
  /**
   * Interval between minutes in picker
   */
  minuteStep?: number;
  /**
   * Callback function executed when the selected time is changing
   */
  onChange?: (time: moment.Moment, timeString: string) => void;
  /**
   * Callback function executed when the selected time is changing
   */
  onSelect?: (time: moment.Moment) => void;
  /**
   * Callback function which will be called while panel opening/closing
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Whether to popup panel
   */
  open?: boolean;
  /**
   * Display when there's no value
   */
  placeholder?: string;
  /**
   * Interval between seconds in picker
   */
  secondStep?: number;
  /**
   * Size of the picker
   */
  size?: "large" | "small";
  /**
   * The custom suffix icon
   */
  suffixIcon?: React.ReactNode;
  /**
   * Display as 12 hours format, with default format h:mm:ss a
   */
  use12Hours?: boolean;
  /**
   * The time value
   */
  value?: moment.Moment;
  /**
   *	Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}

interface INativeTimePickerProps {
  className?: string;
}

const NativeTimePicker = styled<
  React.FunctionComponent<ITimePickerProps & INativeTimePickerProps>
>(props => {
  const newProps: any = { ...props };
  delete newProps.format;
  delete newProps.size;
  delete newProps.children;
  delete newProps.disabled;
  delete newProps.use12Hours;
  delete newProps.value;
  const value = props.value ? props.value.format("HH:mm") : undefined;

  return (
    <span className="ant-time-picker">
      <div>
        <input type="time" value={value} {...newProps} />
        <Icon type="fa-clock fas" />
      </div>
    </span>
  );
})`
  min-width: 14em;
`;

/**
 * An input component for entering time.
 */
export class TimePicker extends React.PureComponent<ITimePickerProps> {
  constructor(props: ITimePickerProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  public render() {
    if (isMobile) {
      const newProps: any = { ...this.props };
      delete newProps.onChange;
      let className = "ant-calendar-time-picker-input ant-input";
      if (this.props.disabled && this.props.disabled === true) {
        className += " ant-input-disabled";
      }
      if (this.props.size === "large") {
        className += " ant-input-lg";
      } else if (this.props.size === "small") {
        className += " ant-input-sm";
      }
      return (
        <NativeTimePicker
          className={className}
          {...newProps}
          onChange={this.handleChange}
        />
      );
    } else {
      const getPopupContainer = this.props.getPopupContainer
        ? this.props.getPopupContainer
        : (trigger: HTMLElement) => trigger.parentElement as HTMLElement;
      return (
        <AntTimePicker {...this.props} getPopupContainer={getPopupContainer} />
      );
    }
  }
  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const stringToMoment = moment;
    if (this.props.onChange) {
      this.props.onChange(
        stringToMoment(event.target.value, "HH:mm"),
        event.target.value
      );
    }
  }
}
