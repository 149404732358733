import { Layout as AntLayout } from "antd";
import * as React from "react";

export interface ILayoutHeaderProps {
  /**
   * Wether layout contains Sider in children, don't have to assign it normally.
   */
  hasSider?: boolean;
}
/**
 * Layout header component.
 */
export class LayoutHeader extends React.PureComponent<ILayoutHeaderProps> {
  public render() {
    return <AntLayout.Header {...this.props} />;
  }
}
