import * as React from "react";
import styled from "styled-components";

export interface IOverlayProps {
  /**
   * Container contents
   */
  children?: React.ReactNode;
  /**
   * Reserved.
   */
  className?: string;
  /**
   * Background color of overlay
   */
  color?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Defines the height of the overlay
   */
  height?: string;
  /**
   * Opacity
   */
  opacity?: number;
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Defines the width of the overlay
   */
  width?: string;
  /**
   * Placement of the overlay within the layers of the page
   */
  zIndex?: number;
}

const StyledOverlay = styled<React.FunctionComponent<IOverlayProps>>(props => {
  const newProps: any = { ...props };
  delete newProps.opacity;
  delete newProps.height;
  delete newProps.color;
  delete newProps.width;
  delete newProps.variation;
  delete newProps.zIndex;
  return <div {...newProps} />;
})`
  && {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${props => (props.zIndex ? "z-index: " + props.zIndex + ";" : "1000;")}
      ${props => (props.height ? "height: " + props.height + ";" : "")}
      ${props => (props.opacity ? "opacity: " + props.opacity + ";" : "")}
    ${props => (props.width ? "width: " + props.width + ";" : "")}
    }
  `;

/**
 * Container component that overlays the current content.
 */
export class Overlay extends React.PureComponent<IOverlayProps> {
  public render() {
    const color = this.props.color ? this.props.color! : "default";
    const className =
      "ant-bg-" +
      color +
      (this.props.variation ? "-" + this.props.variation : "");
    return <StyledOverlay className={className} {...this.props} />;
  }
}
