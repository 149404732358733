import { Popover as AntPopover } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IAlignType {
  /**
   * move point of source node to align with point of target node.
   * Such as ['tr','cc'], align top right point of source node with center point of target node.
   * Point can be 't'(top), 'b'(bottom), 'c'(center), 'l'(left), 'r'(right) */
  points?: string[];
  /**
   * offset source node by offset[0] in x and offset[1] in y.
   * If offset contains percentage string value, it is relative to sourceNode region.
   */
  offset?: number[];
  /**
   * offset target node by offset[0] in x and offset[1] in y.
   * If targetOffset contains percentage string value, it is relative to targetNode region.
   */
  targetOffset?: number[];
  /**
   * If adjustX field is true, will adjust source node in x direction if source node is invisible.
   * If adjustY field is true, will adjust source node in y direction if source node is invisible.
   */
  overflow?: {
    adjustX?: boolean | number;
    adjustY?: boolean | number;
  };
  /**
   * Whether use css right instead of left to position
   */
  useCssRight?: boolean;
  /**
   * Whether use css bottom instead of top to position
   */
  useCssBottom?: boolean;
  /**
   * Whether use css transform instead of left/top/right/bottom to position if browser supports.
   * Defaults to false.
   */
  useCssTransform?: boolean;
  ignoreShake?: boolean;
}

export interface IPopoverProps {
  align?: IAlignType;
  /**
   * Content of the card
   */
  content?: string | React.ReactNode;
  /**
   * Hides the arrow from the tooltip
   */
  hideArrow?: boolean;
  /**
   * Callback executed when visibility of the popover is changed
   */
  onVisibleChange?: (visible: boolean) => void;
  /**
   * The position of the popover relative to the target.
   */
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  /**
   * Set HTML node from which the popover will appear
   */
  ref?: (node: any) => void;
  /**
   * title of the box
   */
  title?: string | React.ReactNode;
  /**
   * Popover trigger mode
   */
  trigger?: "hover" | "focus" | "click" | "contextMenu";
  /**
   * Whether the popover is visible or not
   */
  visible?: boolean;
  /**
   *	Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}

/**
 * Popover component.
 */
const StyledPopover = styled(({ className, ...props }) => (
  <AntPopover {...props} overlayClassName={className} />
))`
  && {
    ${(props: IPopoverProps) =>
      props.hideArrow &&
      `
    .ant-popover-arrow {
        display: none;
    }`}
  }
`;

export class Popover extends React.PureComponent<IPopoverProps> {
  public render() {
    const getPopupContainer = this.props.getPopupContainer
      ? this.props.getPopupContainer
      : (trigger: HTMLElement) => trigger.parentElement as HTMLElement;

    return (
      <StyledPopover {...this.props} getPopupContainer={getPopupContainer} />
    );
  }
}
