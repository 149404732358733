import { Button as AntButton } from "antd";
import * as React from "react";
import styled from "styled-components";
import { checkSizeProps, screenSizeFinder } from "../../screenSizeHandler";
import { alert6 } from "../../Constants";

export interface IButtonProps {
  /**
   * Option to fit button width to its parent width
   */
  block?: boolean;
  /**
   * Remove border
   */
  borderless?: boolean;
  /**
   * Button label
   */
  children?: React.ReactNode;
  /**
   * Whether button is disabled
   */
  disabled?: boolean;
  /**
   * Make background transparent and invert text and border colors
   */
  ghost?: boolean;
  /**
   * Redirect url of link button
   */
  href?: string;
  /**
   * Set the original html type of button
   */
  htmlType?: "submit" | "button" | "reset";
  /**
   * id of the element
   */
  id?: string;
  /**
   * Set the loading status of button
   */
  loading?: boolean | { delay?: number };
  /**
   * Set the handler to handle click event
   */
  onClick?: React.MouseEventHandler<Element>;
  /**
   * Can be set to circle or omitted
   */
  shape?: "circle" | "circle-outline";
  /**
   * Can be set to small large or omitted
   */
  size?: "small" | "default" | "large" | "x-large";
  /**
   * Same as target attribute of a, works when href is specified
   */
  target?: string;
  /**
   * Can be set to primary ghost dashed danger(added in 2.7) or omitted (meaning default)
   */
  type?: "default" | "primary" | "ghost" | "dashed" | "danger" | "alert";
  /**
   * ≥576px, could be a span value
   */
  sm?: boolean;
  /**
   * ≥768px, could be a span value
   */
  md?: boolean;
  /**
   * ≥992px, could be a span value
   */
  lg?: boolean;
  /**
   * ≥1200px, could be a span value
   */
  xl?: boolean;
  /**
   * <576px could be a span value
   */
  xs?: boolean;
  /**
   * ≥1600px could be a span value
   */
  xxl?: boolean;
}

const StyledAntButton = styled<React.FunctionComponent<IButtonProps>>(
  (props: IButtonProps) => {
    const newProps: any = { ...props };
    delete newProps.borderless;
    delete newProps.xs;
    delete newProps.sm;
    delete newProps.md;
    delete newProps.lg;
    delete newProps.xl;
    delete newProps.xxl;
    if (props.size === "x-large") {
      delete newProps.size;
    }
    if (props.type === "alert") {
      newProps.type = "ghost";
    }
    return <AntButton {...newProps} />;
  }
)`
&& {
    &:hover {
        ${props => (props.type === "alert" ? "background-color: white;" : "")}
    }
    ${props => (props.borderless ? "border: none; box-shadow: none;" : "")}
    ${props => (props.size === "x-large" ? "height: 64px;" : "")}
    ${props =>
      props.type === "alert"
        ? `color: ${alert6}; border-color: ${alert6};`
        : ""}
    }
 `;

interface IButtonState {
  width?: number;
}

/**
 * To trigger an operation.
 */
export class Button extends React.PureComponent<IButtonProps, IButtonState> {
  constructor(props: IButtonProps) {
    super(props);
    this.state = {};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  public componentDidMount() {
    if (checkSizeProps(this.props)) {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }
  }

  public componentWillUnmount() {
    if (checkSizeProps(this.props)) {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }
  }

  public render() {
    const displayState =
      this.state.width !== undefined
        ? this.props[screenSizeFinder(this.state.width)]
        : true;
    const visible = displayState === undefined ? true : displayState;
    return visible ? <StyledAntButton {...this.props} /> : null;
  }

  private updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
}
