import { Menu as AntMenu } from "antd";
import * as React from "react";
import styled from "styled-components";
import { IClickParam } from "../Core/IClickParam";
import { ISelectParam } from "../Core/ISelectParam";

export interface IMenuProps {
  /**
   * Keys of default opened sub menus
   */
  defaultOpenKeys?: string[];
  /**
   * Keys of default selected menu items
   */
  defaultSelectedKeys?: string[];
  /**
   * Specifies the collapsed status when menu is inline mode
   */
  inlineCollapsed?: boolean;
  /**
   * Indent px of inline menu item on each level
   */
  inlineIndent?: number;
  /**
   * Defines space around outside of menu using CSS margin syntax
   */
  margin?: string;
  /**
   * Type of the menu
   */
  mode?:
    | "vertical"
    | "vertical-left"
    | "vertical-right"
    | "horizontal"
    | "inline";
  /**
   * Allow selection of multiple items
   */
  multiple?: boolean;
  /**
   * Callback executed when a menu item is clicked
   */
  onClick?: (param: IClickParam) => void;
  /**
   * Callback executed when a menu item is deselected, only supported for multiple mode
   */
  onDeselect?: (param: ISelectParam) => void;
  /**
   * Callback executed when sub menu is open/closed.
   */
  onOpenChange?: (openKeys: string[]) => void;
  /**
   * Callback executed when a menu item is selected
   */
  onSelect?: (param: ISelectParam) => void;
  /**
   * Allow selecting menu items
   */
  selectable?: boolean;
  /**
   * Array with the keys of currently selected menu items
   */
  selectedKeys?: string[];
  /**
   * Delay time to hide submenu when mouse leave, unit: second
   */
  subMenuCloseDelay?: number;
  /**
   * Delay time to show submenu when mouse enter, unit: second
   */
  subMenuOpenDelay?: number;
  /**
   * Color theme of the menu
   */
  theme?: "light" | "dark";
}

const StyledMenu = styled<React.FunctionComponent<IMenuProps>>(props => {
  const newProps: any = { ...props };
  delete newProps.margin;
  return <AntMenu {...newProps} />;
})`
  && {
    ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
  }
`;

/**
 * A menu.
 */
export class Menu extends React.PureComponent<IMenuProps> {
  public render() {
    return <StyledMenu {...this.props} />;
  }
}
