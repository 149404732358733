import * as React from "react";

export interface IImageProps {
  /**
   * Defines the alternative text describing the image
   */
  alt?: string;
  /**
   * Url of image
   */
  src: string;
  /**
   * Height of image
   */
  height?: string;
  /**
   * Width of image
   */
  width?: string;
}

/**
 * An Image
 */
export class Image extends React.PureComponent<IImageProps> {
  public render() {
    return <img {...this.props} />;
  }
}
