import { Row as AntRow } from "antd";
import * as React from "react";
import styled from "styled-components";

export interface IRowProps {
  /**
   * The vertical alignment of the flex layout
   */
  align?: "top" | "middle" | "bottom";
  /**
   * Flex Items align more dynamic than align
   */
  alignItems?:
    | "center"
    | "stretch"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "initial"
    | "inherit";
  /**
   * Prevent page and column breaks within the row
   */
  breakInside?: "auto" | "avoid" | "avoid-page" | "avoid-column";
  /**
   * Color
   */
  color?:
    | "primary"
    | "default"
    | "success"
    | "warning"
    | "error"
    | "navbar"
    | "alert";
  /**
   * Direction of the flex rows
   */
  direction?: "column" | "row";
  /**
   * Flex grow value that dictates the height of the row as a proportion of the grid.
   */
  grow?: number;
  /**
   * Spacing between grids, could be a number or a object like { xs: 8, sm: 16, md: 24}
   */
  gutter?:
    | number
    | Partial<Record<"xxl" | "xl" | "lg" | "md" | "sm" | "xs", number>>;
  /***
   * Height of the row
   */
  height?: string;
  /**
   * Horizontal arrangement of the flex layout
   */
  justify?: "start" | "end" | "center" | "space-around" | "space-between";
  /**
   * Defines space around outside of column using CSS margin syntax
   */
  margin?: string;
  /**
   * Funtion to be fired when row clicked.
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Defines space around the column's content using CSS padding syntax
   */
  padding?: string;
  /**
   * Enable scrolling
   */
  scroll?: "horizontal" | "vertical" | "both";
  /**
   * Indicates whether row contents are selectable.
   * @default true
   */
  selectable?: boolean;
  /**
   * Flex shrink value for the row.
   */
  shrink?: number;
  /**
   * Layout mode
   */
  type?: "flex" | "responsive";
  /**
   * Specifies lighter or darker variation of the background color of the grid.
   */
  variation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Allow columns to wrap
   */
  wrap?: boolean;
  /**
   * ref for accessing dom node
   */
  innerRef?: React.RefObject<any>;
}

const StyledRow = styled<React.FunctionComponent<IRowProps>>(props => {
  const newProps: any = { ...props };
  delete newProps.wrap;
  delete newProps.type;
  delete newProps.scroll;
  delete newProps.color;
  delete newProps.selectable;
  delete newProps.margin;
  delete newProps.padding;
  delete newProps.height;
  delete newProps.variation;
  delete newProps.grow;
  delete newProps.alignItems;
  delete newProps.shrink;
  delete newProps.innerRef;
  delete newProps.direction;
  delete newProps.type;
  return <AntRow {...newProps} ref={props.innerRef} />;
})`
&& {
    ${props => (props.padding ? "padding: " + props.padding + ";" : "")}
  ${props => (props.margin ? "margin: " + props.margin + ";" : "")}
    ${props => (props.wrap === true ? "flex-wrap: nowrap;" : "")}
    ${props => (props.grow ? "flex-grow: " + props.grow + ";" : "")}
    ${props => (props.shrink ? "flex-shrink: " + props.shrink + ";" : "")}
    ${props => (props.height ? "height: " + props.height + ";" : "")}
    ${props => (props.type === "flex" ? "display: flex;" : "")}
    ${props => (props.direction === "column" ? "flex-direction: column;" : "")}
    ${props =>
      props.alignItems !== undefined
        ? "align-items: " + props.alignItems + ";"
        : ""}
    ${props =>
      props.breakInside ? "break-inside: " + props.breakInside + ";" : ""}
    ${props => (props.onClick ? "cursor: pointer;" : "")}
}
`;

/**
 * Row component for grid layouts.
 */
export class Row extends React.PureComponent<IRowProps> {
  public render() {
    let className: any;
    let classes: string = "";
    if (this.props.scroll !== undefined) {
      if (this.props.scroll === "vertical" || this.props.scroll === "both") {
        classes = classes + "ant-scroll-vertical ";
      }
      if (this.props.scroll === "horizontal" || this.props.scroll === "both") {
        classes = classes + "ant-scroll-horizontal ";
      }
    }
    if (
      this.props.selectable !== undefined &&
      this.props.selectable === false
    ) {
      classes = classes + "unselectable ";
    }
    if (this.props.color !== undefined) {
      classes =
        classes +
        "ant-bg-" +
        this.props.color +
        (this.props.variation ? "-" + this.props.variation : "");
    }
    if (classes !== "") {
      className = { className: classes };
    }
    return <StyledRow {...className} {...this.props} />;
  }
}
